<template>
	<section class="section">
		<div class="bg-white">
			<div class="container top">
				<div class="page-head py-30">
					<div class="content-info">
						<div class="content-info__item">
							<div class="mb-10">
								<AppText size="14" line-height="16" weight="600"
									>{{ $t("numberOfBidsSubmitted") | toUpperCase }}:
								</AppText>
							</div>
							<div class="d-flex">
								<div class="d-flex flex-column">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="16" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("perday") }} </AppText
										>&nbsp;
										<AppText size="19" line-height="22" weight="600">
											{{ shopOffertDealsInfo.viszaden | price }}
										</AppText>
									</div>
									<!-- <AppText size="14" line-height="17" class="color-green" weight="500">
										{{ shopOffertDealsInfo.viszadensumm | price }} UZS
									</AppText> -->
								</div>
								<div class="d-flex flex-column ml-40">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="16" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("inAYear") }} </AppText
										>&nbsp;
										<AppText size="19" line-height="22" weight="600"
											>{{ shopOffertDealsInfo.viszagod }}
										</AppText>
									</div>
									<!-- <AppText size="14" line-height="17" class="color-green" weight="500"
										>{{ shopOffertDealsInfo.viszagodsumm | price }} UZS
									</AppText> -->
								</div>
							</div>
						</div>
						<!-- <div class="content-info__item"> -->
						<!-- <div class="mb-10">
								<AppText size="14" line-height="16" weight="600"
									>{{ $t("dealAmount") | toUpperCase }}:
								</AppText>
							</div>
							<div class="d-flex">
								<div class="d-flex flex-column">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="22" line-height="22" weight="600">
											{{ shopOffertDealsInfo.dealzaden }} </AppText
										>&nbsp;
										<AppText size="14" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("perday") }}
										</AppText>
									</div>
									<AppText size="14" line-height="17" class="color-green" weight="500">
										{{ shopOffertDealsInfo.dealzadensumm | price }} UZS
									</AppText>
								</div>
								<div class="d-flex flex-column ml-40">
									<div class="mb-5 d-flex align-flex-end">
										<AppText size="22" line-height="22" weight="600">
											{{ shopOffertDealsInfo.dealzagod }} </AppText
										>&nbsp;
										<AppText size="14" line-height="22" weight="600" class="text-nowrap"
											>{{ $t("yearly") }}
										</AppText>
									</div>
									<AppText size="14" line-height="17" class="color-green" weight="500"
										>{{ shopOffertDealsInfo.dealzagodsumm | price }} UZS
									</AppText>
								</div>
							</div> -->
						<!-- </div> -->
						<div class="content-infoSupport">
							<div class="cursor-pointer" @click="routeFilter('/product-list/category/green-trade')">
								<div
									class="nd_shop_block"
									:class="{
										'active-green': $route.path === '/product-list/category/green-trade',
									}"
								>
									<div class="nd_shop_block-image">
										<img src="@/assets/icons/green-busket.svg" class="mr-15" alt="" />
									</div>
									<AppText size="14" line-height="17" weight="500">
										{{ $t("greenShopping") }}
									</AppText>
								</div>
							</div>
							<div class="cursor-pointer" @click="routeFilter('/product-list/category/22')">
								<div
									class="nd_shop_block"
									:class="{
										active: $route.params.categoryId === '22',
									}"
								>
									<img src="@/assets/icons/shop_img1.svg" class="mr-15" alt="" />
									<AppText size="14" line-height="17" weight="500">
										{{ $t("goodsOfOrganizationsRelatedPersonsWithDisabilities") }}
									</AppText>
								</div>
							</div>
							<div class="cursor-pointer" @click="routeFilter('/product-list/category/19')">
								<div
									class="nd_shop_block"
									:class="{
										active: $route.params.categoryId === '19',
									}"
								>
									<img src="@/assets/icons/shop_img2.svg" class="mr-15" alt="" />
									<AppText size="14" line-height="17" weight="500">
										{{ $t("electricalEquipmentUzeltekhsanoat") }}
									</AppText>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="page-breadcrumb py-10" v-if="breadCrumbList">
					<app-breadcrumb :items="breadCrumbList" />
				</div>
			</div>
		</div>

		<section class="section pt-30 bg-primary pb-50">
			<div class="container">
				<div class="section__wrapper">
					<div class="section__top d-flex flex-column flex-nowrap">
						<header class="auction-header w-100 mb-20" :class="isMobileMedium ? 'flex-wrap' : ''">
							<!--							<app-text-->
							<!--								tag="h1"-->
							<!--								size="26"-->
							<!--								line-height="34"-->
							<!--								weight="600"-->
							<!--								:class="[isMobileMedium ? 'w-100' : '', isMobileMedium ? 'mb-20' : '']"-->
							<!--								class="mr-30"-->
							<!--							>-->
							<!--								{{ displayTitle }}-->
							<!--							</app-text>-->
							<app-content-card padding-x="0" padding-y="0" :radius="[8, 8, 0, 0]">
								<app-tabs v-model="selectedTab" :list="tabItems" @input="handleTabChange" />
							</app-content-card>
							<div class="d-flex align-center auction-header-actions">
								<a
									v-if="!$store.getters.isLoggedIn"
									style="margin-right: 15px"
									:href="`${adminUrl}/#/login?search`"
								>
									<app-button
										theme="main"
										class="w-100 font_Hsemibold"
										sides="16"
										height="40"
										:font-size="isMobile ? '12' : '14'"
										>{{ $t("loginForInfo") }}
									</app-button>
								</a>
								<app-button
									v-else
									theme="main"
									sides="16"
									height="40"
									font-size="14"
									style="margin-right: 15px"
									@click="redirectToSearch"
								>
									{{ $t("getInformation") }}
								</app-button>
								<div class="auction-header_inputs">
									<app-input
										v-if="!$route.params.categoryId"
										@keypress.enter="onInputCategory()"
										v-model="query.filter"
										class="auction-header__input ml-auto"
										:placeholder="this.$t('category') + '...'"
										@input="onInputCategory()"
									>
										<template #prepend>
											<img src="@/assets/icons/search.svg" style="width: 16px" alt />
										</template>
									</app-input>
									<app-input
										@keypress.enter="onInputProduct()"
										v-model="query.ProductName"
										class="auction-header__input ml-auto"
										:placeholder="this.$t('productName') + '...'"
										@input="onInputProduct()"
									>
										<template #prepend>
											<img src="@/assets/icons/search.svg" style="width: 16px" alt />
										</template>
									</app-input>

								<app-button-a
									v-if="$route.params.categoryId"
									@click="isOpenFilterPanel = !isOpenFilterPanel"
									:width="0"
									class="auction-header__button"
									theme="gray-light"
									padding-x="24"
									font-weight="600"
								>
									<img src="@/assets/icons/sliders.svg" class="mr-10" alt />
									<span>{{ $t("filter") }}</span>
								</app-button-a>
								</div>
							</div>
						</header>
					</div>

					<transition name="fade" class="mb-20">
						<app-content-card class="auction-filter mb-20" border v-if="isOpenFilterPanel">
							<block-wrap
								class="auction-filter__items-wrap w-100"
								offset-x="15"
								offset-y="15"
								:count="isMobileSmall ? 1 : isMobile ? 3 : 4"
							>
								<form-label
									v-model="query.CategoryId"
									class="auction-filter__item"
									:list="categoryList"
									item-value="id"
									component-type="dropdown"
									:title="this.$t('category')"
									:translatable="true"
									:placeholder="this.$t('chooseCategory')"
								/>
								<form-label
									v-model="query.ProductName"
									class="auction-filter__item"
									:title="this.$t('product')"
								/>
								<!-- <form-label
									v-model="query.manufacturer"
									class="auction-filter__item"
									:title="this.$t('manufacturer')"
								/> -->
								<div class="grid-row">
									<form-label
										class="auction-filter__item"
										:title="this.$t('unitPrice')"
										:placeholder="this.$t('from')"
										v-model="query.From"
									/>
									<form-label
										class="auction-filter__item"
										v-model="query.To"
										:placeholder="this.$t('until')"
									/>
								</div>
								<div class>
									<div class="d-flex flex-column">
										<!-- <app-text class="form-label__title" size="12" line-height="16"
											>{{ $t("TNVEDCode") }}
										</app-text> -->

										<form-label
											:list="tnvedList"
											:title="$t('TNVEDCode')"
											v-model="query.CompanyTnVed"
											option-value="code"
											option-text="nameCode"
										></form-label>
									</div>
								</div>
							</block-wrap>

							<div class="auction-filter__buttons-wrap">
								<app-button-a
									@click="resetFilter()"
									:width="0"
									padding-x="10"
									class="auction-filter__reset-button color-text-light font_Hsemibold"
									theme="transparent"
									flex
								>
									<img src="@/assets/icons/trash.svg" class="mr-10" alt style="width: 18px" />
									{{ $t("clearFilter") }}
								</app-button-a>
								<div class="auction-filter__buttons font_Hsemibold">
									<app-button-a
										@click="isOpenFilterPanel = false"
										class="mr-15"
										width="108"
										theme="gray-light"
										>{{ $t("cancel") }}
									</app-button-a>
									<app-button-a @click="load" width="108" paddingX="20">
										{{ $t("show") }}
									</app-button-a>
								</div>
							</div>
						</app-content-card>
					</transition>

					<template v-if="selectedTab === 'goods'">
						<div class="section__body">
							<block-wrap
								:count="isMobileSmall ? 1 : isMobile ? 2 : isDesktopSmall ? 2 : isDesktopMedium ? 3 : 4"
								offset-y="20"
								offset-x="20"
							>
								<template
									v-if="
										allTnVedCategory.length > 0 &&
										!categoryLoader &&
										!$route.params.categoryId &&
										!$route.params.regionId
									"
								>
									<NewCatalogCard
										v-for="(category, idx) in allTnVedCategory"
										class="cursor-pointer"
										:key="idx"
										:item="category"
										@click="getByCategory(category.id)"
									/>
								</template>
								<template
									v-if="
										!allTnVedCategory.length &&
										query &&
										!query.ProductName &&
										!$route.params.categoryId &&
										categoryLoader
									"
								>
									<!-- Vue 2 :key bind warning -->
									<NewCatalogCardLoader />
									<NewCatalogCardLoader />
									<NewCatalogCardLoader />
									<NewCatalogCardLoader />
									<!-- Vue 2 :key bind warning -->
								</template>
								<template v-if="list.length > 0">
									<a
										v-for="(item, idx) in list"
										:key="idx"
										:href="`/#/product-list/${item.offerNumber}`"
										target="_blank"
									>
										<app-product-card :product="item" />
									</a>
								</template>
								<!-- <template
								v-if="
									query &&
									(query.ProductName || $route.params.categoryId || $route.params.regionId) &&
									!list.length &&
									listLoader
								"
							> -->
								<template
									v-if="
										(query.ProductName || $route.params.categoryId || $route.params.regionId) &&
										listLoader &&
										!loaded
									"
								>
									<!-- Vue 2 :key bind warning -->
									<AppProductCardSkeleton />
									<AppProductCardSkeleton />
									<AppProductCardSkeleton />
									<AppProductCardSkeleton />
									<!-- Vue 2 :key bind warning -->
								</template>
								<template
									v-if="
										loaded &&
										((($route.params.regionId || $route.params.categoryId) && !list.length) ||
											(!$route.params.regionId &&
												!$route.params.categoryId &&
												!allTnVedCategory.length &&
												!list.length) ||
											(query.ProductName && !list.length))
									"
								>
									<NotFound />
								</template>
							</block-wrap>
						</div>
					</template>
					<template v-if="selectedTab === 'worksAndServices'">
						<div class="section__body">
							<block-wrap
								:count="isMobileSmall ? 1 : isMobile ? 2 : isDesktopSmall ? 2 : isDesktopMedium ? 3 : 4"
								offset-y="20"
								offset-x="20"
							>
								<template
									v-if="
										filteredEnktList.length > 0 &&
										!categoryLoader &&
										!$route.params.categoryId &&
										!$route.params.regionId
									"
								>
									<NewCatalogCard
										v-for="(category, idx) in filteredEnktList"
										class="cursor-pointer"
										:key="idx"
										:item="category"
										@click="getByCategory(category.id)"
									/>
								</template>
								<template
									v-if="
										!filteredEnktList.length &&
										query &&
										!query.ProductName &&
										!$route.params.categoryId &&
										categoryLoader
									"
								>
									<!-- Vue 2 :key bind warning -->
									<NewCatalogCardLoader />
									<NewCatalogCardLoader />
									<NewCatalogCardLoader />
									<NewCatalogCardLoader />
									<!-- Vue 2 :key bind warning -->
								</template>
								<template v-if="list.length > 0">
									<a
										v-for="(item, idx) in list"
										:key="idx"
										:href="`/#/product-list/${item.offerNumber}`"
										target="_blank"
									>
										<app-product-card :product="item" />
									</a>
								</template>
								<template
									v-if="
										(query.ProductName || $route.params.categoryId || $route.params.regionId) &&
										listLoader &&
										!loaded
									"
								>
									<!-- Vue 2 :key bind warning -->
									<AppProductCardSkeleton />
									<AppProductCardSkeleton />
									<AppProductCardSkeleton />
									<AppProductCardSkeleton />
									<!-- Vue 2 :key bind warning -->
								</template>
								<template
									v-if="
										loaded &&
										((($route.params.regionId || $route.params.categoryId) && !list.length) ||
											(!$route.params.regionId &&
												!$route.params.categoryId &&
												!filteredEnktList.length &&
												!list.length) ||
											(query.ProductName && !list.length))
									"
								>
									<NotFound />
								</template>
							</block-wrap>
						</div>
					</template>
					<!-- <app-pagination/> -->
					<pagination
						v-if="typeOfPage"
						:total="total"
						:perPage="pageSettings"
						@paginate="pagination($event)"
					/>
				</div>
			</div>
		</section>
	</section>
</template>

<script>
import "./product-list.scss";
import AppProductCardSkeleton from "../../components/shared-components/AppProductCardSkeleton";
import NewCatalogCardLoader from "../../components/shared-components/NewCatalogCardLoader";
import NewCatalogCard from "../../components/shared-components/NewCatalogCard";
import ProductListStatistics from "../../components/pages/ProductList/ProductListStatistics";
import AppText from "../../components/shared-components/AppText";
import AppButton from "../../components/shared-components/AppButton";
import DynamicIcon from "../../components/shared-components/DynamicIcon";
import AppInput from "../../components/shared-components/AppInput";
import BlockWrap from "../../components/shared-components/BlockWrap";
import AppDropdown from "../../components/shared-components/AppDropdown";
import AppContentCard from "../../components/shared-components/AppContentCard";
import FormLabel from "../../components/shared-components/FormLabel";
import AppButtonA from "../../components/shared-components/AppButtonA";
import AppProductCard from "../../components/shared-components/AppProductCard";
import ShopService from "@/services/api/shop-service";
import ReferenceService from "@/services/api/reference-service";
import AppPagination from "../../components/shared-components/AppPagination";
import Pagination from "../../components/shared-components/Pagination";
import AppBreadcrumb from "../../components/shared-components/AppBreadcrumb";
import NotFound from "../../components/shared-components/NotFound";
import AppTabs from "@/components/shared-components/AppTabs.vue";
import i18n from "@/plugins/vue-i18n-config";

export default {
	name: "index",
	components: {
		NotFound,
		AppProductCardSkeleton,
		NewCatalogCardLoader,
		AppProductCard,
		NewCatalogCard,
		AppButtonA,
		FormLabel,
		AppContentCard,
		AppDropdown,
		BlockWrap,
		AppInput,
		DynamicIcon,
		AppButton,
		AppText,
		ProductListStatistics,
		Pagination,
		AppPagination,
		AppBreadcrumb,
		AppTabs,
	},
	data() {
		return {
			adminUrl: process.env.VUE_APP_ADMIN_URL,
			list: [],
			enktList: [],
			query: {
				CategoryId: null,
				FirstTnvedCategoryId: null, //tovarlar
				FirstEnktCategoryCode: null, //ishlar va xizmatlar
				ProductName: null,
				CountryId: null,
				Manufacturer: null,
				From: null,
				To: null,
				IsLocalManufacturer: null,
				CompanyTnVed: [],
				skip: 0,
				take: 12,
				filter: null,
			},
			total: 0,
			cleanQuery: {},
			categoryList: [],
			countries: [],
			filterToggle: false,
			isOpenFilterPanel: false,
			tnvedList: [],
			shopOffertDealsInfo: {},
			allTnVedCategory: [],
			route: this.$route,
			timeout: null,
			timeoutForCategory: null,
			listLoader: false,
			categoryLoader: false,
			loaded: false,
			currentCategory: [],
			selectedTab: "goods",
			tabItems: [
				{
					name: i18n.t("goods"),
					value: "goods",
				},
				{
					name: i18n.t("work_and_services"),
					value: "worksAndServices",
				},
			],
		};
	},
	async created() {
		await this.getOffertDealsInfo();
		// this.getAllTnved();
		await this.getProductCategories();
		this.cleanQuery = JSON.parse(JSON.stringify(this.query));
		if (this.$route.params.categoryId || this.$route.params.regionId) {
			await this.load();
		} else {
			await this.getAllTnVedCategory();
		}
	},
	methods: {
		redirectToSearch() {
			let token = localStorage.getItem("Authorization");
			let esiId = localStorage.getItem("esiId");
			let refreshToken = localStorage.getItem("Refresh");
			let name = this.$store.state.userInfo.name["ru"];
			window.location.href = `https://malumotnoma.cooperation.uz?token=${token}&esiId=${esiId}&name=${name}&refresh=${refreshToken}`;
			// this.$router.push("/searching-offer");
		},

		handleTabChange(newTab) {
			this.selectedTab = newTab;

			if (this.$route.path.includes("/product-list/category")) {
				this.$router.push("/product-list");
			}
		},

		async load() {
			try {
				this.list = [];
				this.query.filter = null;
				this.allTnVedCategory = [];
				let params = { ...this.query };
				let response = {};
				this.loaded = false;

				if (this.$route.path === "/product-list/category/green-trade") {
					return;
				}

				this.listLoader = true;

				const categoryId = this.$route.params.categoryId;
				const regionId = this.$route.params.regionId;

				const enktHasCount = this.enktList.some((item) => item.count > 0);

				if (!regionId) {
					if (categoryId) {
						if (!isNaN(Number(categoryId))) {
							params.FirstTnvedCategoryId = Number(categoryId);
						} else {
							params.FirstEnktCategoryCode = categoryId;
						}
					}

					response = await ShopService.getAllOffer(params);

					this.currentCategory = response.data.result.category;
				} else {
					params.regionId = regionId;
					response = await ShopService.getAllOfferByRegion(params);
				}

				this.list = response.data.result.data;
				this.total = response.data.result.total;
			} catch (error) {
				console.error("Error loading data:", error);
			} finally {
				this.categoryLoader = false;
				this.listLoader = false;
				this.loaded = true;
			}
		},

		async getProductCategories() {
			try {
				this.loaded = false;
				this.list = [];
				const response = await ReferenceService.getProductCategories();
				this.categoryList = response.data.result.data;
			} catch (error) {
				this.$notify({ type: "error", text: error });
			} finally {
				this.loaded = true;
			}
		},
		// getAllTnved() {
		// 	this.$api.get(`/api-shop/HSCode/AllCodes`).then((response) => {
		// 		let tnvedList = response.data.result;
		// 		tnvedList.forEach((item) => {
		// 			item.nameCode = item.code + " - " + this.$options.filters.translate(item.name);
		// 		});
		// 		this.tnvedList = tnvedList;
		// 	});
		// },
		async getOffertDealsInfo() {
			try {
				this.$store.commit("setLoadingStatus", true);
				const response = await this.$api.get(`/api-client/Client/ShopOffertDealsCount`);
				this.shopOffertDealsInfo = response.data.result;
			} finally {
				this.$store.commit("setLoadingStatus", false);
			}
		},
		resetFilter() {
			this.query = { ...this.cleanQuery };
		},
		pagination(skip) {
			this.query.skip = skip - this.query.take;
			this.load();
		},
		filterByInput() {
			this.list = [];
			this.allTnVedCategory = [];
			this.total = 0;
			this.load();
		},
		async getAllTnVedCategory() {
			try {
				this.loaded = false;
				this.total = 0;
				let params = {
					skip: this.query.skip,
					take: 30,
					filter: this.query.filter,
				};
				this.allTnVedCategory = [];
				this.categoryLoader = true;
				const response = await this.$api.get("/api-client/Client/GetAllTnVedCategory", { params });
				const res = response.data.result;
				this.allTnVedCategory = res.data;
				this.total = res.total;
				this.categoryLoader = false;
			} catch {
				this.categoryLoader = false;
			} finally {
				this.loaded = true;
				this.listLoader = false;
			}
		},

		async getAllEnktCategory() {
			try {
				this.loaded = false;
				this.total = 0;
				let params = {
					skip: this.query.skip,
					take: 30,
					filter: this.query.filter,
				};
				const response = await this.$api.get("/api-client/Client/GetAllEnktCategory", { params });
				const res = response.data.result;
				this.enktList = res.data;
				this.total = res.total;
				this.categoryLoader = false;
			} catch (e) {
				console.log(e);
			} finally {
				this.loaded = true;
				this.listLoader = false;
			}
		},
		getByCategory(id) {
			this.$router.push(`/product-list/category/${id}`);
		},

		onInputCategory() {
			clearTimeout(this.timeoutForCategory);
			this.timeoutForCategory = setTimeout(() => {
				this.query.ProductName = "";
				this.list = [];
				this.getAllTnVedCategory();
			}, 300);
		},
		onInputProduct() {
			this.total = 0;
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.query.skip = 0;
				this.query.filter = null;
				this.allTnVedCategory = [];
				if (!this.query.ProductName && !this.$route.params.categoryId && !this.$route.params.regionId) {
					this.list = [];
					this.getAllTnVedCategory();
				} else {
					this.load();
				}
			}, 500);
		},

		routeFilter(path) {
			if (path !== this.$route.fullPath) {
				this.$router.push(path);
			} else {
				this.$router.push("/product-list");
			}
		},

		updateSelectedTab(route) {
			const categoryId = route.params.categoryId;

			if (categoryId) {
				// Check if categoryId is a number or a string (letter)
				if (!isNaN(Number(categoryId))) {
					this.selectedTab = "goods";
				} else {
					this.selectedTab = "worksAndServices";
				}
			} else {
				this.selectedTab = "goods"; // Default value or handle as needed
			}
		},
	},
	watch: {
		"query.ProductName"(val) {
			if (val.length > 50) {
				let str = val;
				let res = str.substring(0, str.length - 1);
				this.query.ProductName = res;
			}
		},
		"query.filter"(val) {
			if (!val) return;
			if (val.length > 50) {
				let str = val;
				let res = str.substring(0, str.length - 1);
				this.query.filter = res;
			}
		},
		$route(to, from) {
			this.total = 0;
			this.query.skip = 0;
			this.allTnVedCategory = [];
			this.list = [];
			this.query.ProductName = "";
			this.isOpenFilterPanel = false;

			if (this.$route.params.categoryId || this.$route.params.regionId) {
				this.load();
			} else {
				this.getAllTnVedCategory();
			}
		},
		// selectedTab(newTab) {
		// 	if (this.$route.path.includes("/product-list/category")) {
		// 		this.$router.push("/product-list");
		// 	}
		// },
	},
	computed: {
		pageSettings() {
			return this.$route.params.categoryId || this.$route.params.regionId || this.query.ProductName
				? this.query.take
				: 30;
		},
		typeOfPage() {
			return this.$route.params.categoryId || this.$route.params.regionId || this.query.ProductName
				? true
				: false;
		},
		displayTitle() {
			if (this.$route.path === "/product-list/category/green-trade") {
				return this.$t("greenShopping");
			} else if (this.$route.params.categoryId) {
				return this.$options.filters.translate(this.currentCategory.name);
			} else {
				return this.$t("eShop");
			}
		},
		filteredEnktList() {
			return this.enktList.map((item) => ({
				...item,
				id: item.code,
			}));
		},
		breadCrumbList() {
			if (this.$route.path === "/product-list") {
				return [
					{ label: this.$t("home"), url: "/" },
					{ label: this.$t("eShop"), url: "/product-list" },
				];
			} else if (this.$route.path === "/product-list/category/green-trade") {
				return [
					{ label: this.$t("home"), url: "/" },
					{ label: this.$t("greenShopping"), url: "/product-list/category/green-trade" },
				];
			} else {
				return [
					{ label: this.$t("home"), url: "/" },
					{ label: this.$t("eShop"), url: "/product-list" },
					{
						label: this.$options.filters.translate(this.currentCategory?.name),
						url: `/product-list/category/${this.currentCategory?.id}`,
					},
				];
			}
		},
	},
	async mounted() {
		await this.getAllEnktCategory();
		this.updateSelectedTab(this.$route);
	},
};
</script>

<style lang="scss" scoped>
.content-infoSupport {
	display: grid;
	width: 80%;
	margin-left: auto;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;

	span {
		height: 100%;

		a {
			display: block;
			height: 100%;

			div {
				height: 100%;
			}
		}
	}
}

.page-head {
	width: 100%;

	.content-inner {
		display: flex;
	}
}
@media (max-width: 1500px) {
	.content-infoSupport {
		width: 100%;
	}
	.page-head {
		padding: 10px 0 !important;
	}
}

@media screen and (max-width: 1024px) {
	.content-infoSupport {
		overflow-x: auto;
		padding-bottom: 5px;
	}
}

.content-info {
	display: grid;
	grid-template-columns: 310px 1fr;
	grid-column-gap: 10px;

	&__item {
		display: flex;
		flex-direction: column;
		min-width: 200px;
	}
}

@media screen and (max-width: 1140px) {
	.content-info {
		display: flex;
		flex-direction: column;
	}
}

.auction-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&__input {
		margin-right: 15px;
		width: 250px;
		border-style: none;
	}

	&__button {
		span {
			vertical-align: baseline;
		}

		svg {
			margin-left: -8px;
		}
	}
}

@media screen and (max-width: 1140px) {
	.auction-header {
		flex-direction: column;
		align-items: start;
		gap: 24px;
	}
}

.auction-header_inputs{
	display: flex;
}

@media screen and (max-width: 768px){
	.auction-header_inputs{
		width: 100%;
		flex-direction: column;
		gap: 10px;

		.auction-header__input{
			width: 100%;
		}
	}
}

@media screen and (max-width: 768px) {
	.auction-header-actions {
		width: 100%;
		flex-direction: column;
		align-items: start;
		gap: 24px;
	}
}

.auction-filter {
	display: flex;
	width: 100%;

	&__item {
		width: #{size(225px, 995px)};
	}

	&__item-large {
		width: #{size(295px, 995px)};
	}

	&__items-wrap {
		width: calc(100% - 200px);
	}

	&__reset-button {
		margin-left: auto;
		margin-bottom: 40px;
		margin-right: -10px;
	}

	&__buttons-wrap {
		padding-top: 26px;
		width: 200px;
	}

	&__buttons {
		display: flex;
	}
}

.nd_shop_block {
	width: 100%;
	display: flex;
	align-items: center;
	color: #0dc9b4;
	border: 1px solid #48c6ef;
	border-radius: 12px;
	background: #fff;
	padding: 10px 12px;
	color: #48c6ef;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
	img {
		height: 40px;
	}

	&-image {
		width: 40px;
		height: 40px;
		background-color: #e6fefb;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 15px;

		img {
			width: 26px;
			height: 26px;
			margin: 0;
		}
	}
	&.active {
		background-color: #48c6ef;
		color: #fff;
	}
	&.active-green {
		background-color: #0dc9b4;
		color: #fff;
	}
}

@media screen and (max-width: 1140px) {
	.nd_shop_block {
		width: 334px;
	}
}

@media (max-width: 800px) {
	.auction-filter__items-wrap {
		width: 100%;
	}

	.auction-filter__buttons-wrap {
		width: 100%;
	}

	.auction-filter__buttons {
		margin-left: 0;
		width: auto;
	}

	.auction-filter__reset-button {
		margin-bottom: 20px;
	}

	.auction-filter__reset-button {
		margin-left: 0;
	}
}
</style>
