<script>
export default {
	name: "ChatOpenner",
	props: {
		localIsChatOpen: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		toggleChat() {
			// Implement your toggle chat logic here
			this.$emit("toggleChat");
		},
	},
};
</script>

<template>
	<button
		v-if="!localIsChatOpen"
		class="chat-button"	type="button"
		@click="toggleChat"
	>
		<svg xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24"><path fill="white" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-4.644-1.142l-4.29 1.117a.85.85 0 0 1-1.037-1.036l1.116-4.289A9.96 9.96 0 0 1 2 12C2 6.477 6.477 2 12 2m1.252 11H8.75l-.102.007a.75.75 0 0 0 0 1.486l.102.007h4.502l.101-.007a.75.75 0 0 0 0-1.486zm1.998-3.5h-6.5l-.102.007a.75.75 0 0 0 0 1.486L8.75 11h6.5l.102-.007a.75.75 0 0 0 0-1.486z"/></svg>
	</button>
</template>

<style scoped lang="scss">
	.chat-button {
		position: fixed;
		cursor: pointer;
		background-color: #2563eb; /* Corresponds to bg-blue-600 */
		border-radius: 9999px; /* Full rounding */
		padding: 1rem; /* p-4 */
		right: 2.5rem; /* right-10 */
		bottom: 2.5rem; /* bottom-10 */
		z-index: 50; /* z-50 */
	}

</style>