import notification from "../plugins/notification";
import i18n from "@/plugins/vue-i18n-config";
import { getPnfl } from "./getPnfl.js";
const EIMZO_MAJOR = 3;
const EIMZO_MINOR = 37;
const errorCAPIWS = "Ошибка соединения с E-IMZO. Возможно у вас не установлен модуль E-IMZO или Браузер E-IMZO.";
const errorUpdateApp =
	'ВНИМАНИЕ !!! Установите новую версию приложения E-IMZO или Браузера E-IMZO.<br /><a href="https://e-imzo.uz/main/downloads/" role="button">Скачать ПО E-IMZO</a>';
const errorBrowserWS = "Браузер не поддерживает технологию WebSocket. Установите последнюю версию браузера.";
const errorWrongPassword = "Пароль неверный.";

String.prototype.replaceAll = function (search, replace) {
	return this.split(search).join(replace);
};
const ESign = {
	EIMZO_MAJOR: EIMZO_MAJOR,

	EIMZO_MINOR: EIMZO_MINOR,
	ERROR_CAPIWS: errorCAPIWS,
	ERROR_UPDATE_APP: errorUpdateApp,
	ERROR_WRONG_PASS: errorWrongPassword,
	ERROR_BROWSER_WS: errorBrowserWS,
	hash: null,
	datakeys: [],
	selectData: {
		id: 0,
		name: "",
		tin: "",
		businessCategory: "",
		region: "",
		district: "",
		dSKeyNumber: "",
		dSKeySerial: "",
		telefon: "",
		email: "",
		confirmPassword: "",
		userLastName: "",
		userFirstName: "",
		userMiddleName: "",
		userPosition: "",
		userInn: "",
	},
	callbackFunction: null,
	info() {
		let itm = document.testform.key.value;
		if (itm) {
			let id = document.getElementById(itm);
			let vo = JSON.parse(id.getAttribute("vo"));
			let alias = vo.alias;
			alias = alias.replaceAll(",", '","').replaceAll("=", '":"');
			let str = '{"' + alias + '"}';
			let obj = JSON.parse(str);
			if (obj.t === "direktor") alert("Director");
			else alert("Not director");
		}
	},
	load() {
		this.clearData();
		EIMZOClient.API_KEYS = [
			"localhost",
			"96D0C1491615C82B9A54D9989779DF825B690748224C2B04F500F370D51827CE2644D8D4A82C18184D73AB8530BB8ED537269603F61DB0D03D2104ABF789970B",
			"127.0.0.1",
			"A7BCFA5D490B351BE0754130DF03A068F855DB4333D43921125B9CF2670EF6A40370C646B90401955E1F7BC9CDBF59CE0B2C5467D820BE189C845D0B79CFC96F",
			//'null', 'E0A205EC4E7B78BBB56AFF83A733A1BB9FD39D562E67978CC5E7D73B0951DB1954595A20672A63332535E13CC6EC1E1FC8857BB09E0855D7E76E411B6FA16E9D',
			"new.cooperation.uz",
			"EA199679060553718E35DF32D0E30D369883D80C0878D6EBB8FDBB9AF2135B675A0408058A67840B81C26DC78C6736D0BDC25FC775802944D75D7AF04D210247",
		];
		EIMZOClient.checkVersion(
			(major, minor) => {
				let newVersion = EIMZO_MAJOR * 100 + EIMZO_MINOR;
				let installedVersion = parseInt(major) * 100 + parseInt(minor);
				if (installedVersion < newVersion) {
					this.uiUpdateApp();
				} else {
					EIMZOClient.installApiKeys(
						() => {
							this.uiLoadKeys();
						},
						(e, r) => {
							if (r) {
								this.uiShowMessage(r);
							} else {
								this.wsError(e);
							}
						}
					);
				}
			},
			(e, r) => {
				if (r) {
					this.uiShowMessage(r);
				} else {
					this.uiNotLoaded(e);
				}
			}
		);
	},

	uiShowMessage(message) {
		alert(message);
	},

	uiNotLoaded(e) {
		let l = document.getElementById("message");
		l.innerHTML = "";
		if (e) {
			this.wsError(e);
		} else {
			this.uiShowMessage(this.ERROR_BROWSER_WS);
		}
	},

	uiUpdateApp() {
		let l = document.getElementById("message");
		l.innerHTML = this.ERROR_UPDATE_APP;
	},

	uiLoadKeys() {
		EIMZOClient.listAllUserKeys(
			(o, i) => {
				return "itm-" + o.serialNumber + "-" + i;
			},
			(itemId, v) => {
				this.addOpt(itemId, v);
				return null;
			},
			(items, firstId) => {
				return "";
			},
			(e, r) => {
				//uiShowMessage(errorCAPIWS);
			}
		);
	},

	wsError(e) {
		if (e) {
			this.uiShowMessage(this.ERROR_CAPIWS + " : " + e);
		} else {
			this.uiShowMessage(this.ERROR_BROWSER_WS);
		}
	},

	addOpt(e, v) {
		let alias = v.alias;
		alias = alias.replaceAll(",", '","').replaceAll("=", '":"');
		let str = '{"' + alias + '"}';
		let obj = JSON.parse(str);
		v.isFizLitso = !("1.2.860.3.16.1.1" in obj);
		this.datakeys.push(v);
	},

	setKey(komp) {
		let alias = komp.alias;
		alias = alias.replaceAll(",", '","').replaceAll("=", '":"');
		let str = '{"' + alias + '"}';

		let obj = JSON.parse(str);
		this.selectData.name = komp.O;
		this.selectData.userPosition = komp.T;
		this.selectData.userFirstName = komp.CN.split(" ")[1];
		this.selectData.userLastName = komp.CN.split(" ")[0];
		this.selectData.userMiddleName = komp.CN.split(" ")[2];
		this.selectData.userInn = komp.UID;
		this.selectData.dSKeySerial = komp.serialNumber;
		this.selectData.dSKeyNumber = komp.name;
		this.selectData.region = obj.st;
		this.selectData.district = obj.l;
		this.selectData.tin = komp.TIN;
		this.selectData.businessCategory = obj.businesscategory;
		this.handleCallbackFunction();
	},

	getKey(item) {
		return new Promise((resolve, reject) => {
			EIMZOClient.loadKey(item, function (val) {
				resolve(val);
			});
		});
	},

	chekPassword(keyId, checkingData) {
		return new Promise((resolve, reject) => {
			EIMZOClient.createPkcs7(
				keyId,
				checkingData,
				null,
				function (val) {
					resolve(val);
				},
				function (err) {
					notification.error("Пароль введен неправильно");
				}
			);
		});
	},

	attachTst(pkcs7, sn, tst, _this, __this) {
		EIMZOClient.attachTst(pkcs7, sn, tst, function (pkcs7tst) {
			__this.hash = pkcs7tst;
			_this.sign();
		});
	},

	getTimeStamp(pkcs7, sn, _this = this._this) {
		_this.$store.commit("setLoadingStatus", false);
		_this.$api
			.post("api-eimzo/eimzo/GetTimeStamp", {
				signature: pkcs7,
			})
			.then(
				(response) => {
					let tst = response?.data?.result?.timeStamp;
					this.attachTst(pkcs7, sn, tst, _this, this);
				},
				(error) => {
					this.$notification.error(error);
				}
			)
			.finally(() => {
				_this.$store.commit("setLoadingStatus", false);
			});
	},

	setESignKeysForAuth(name) {
		let key = this.datakeys.find((item) => item.name === name);
		if (!key) {
			alert(i18n.t("e_sign_not_found"));
			return false;
		}
		this.getKey(key).then((response) => {
			this.chekPassword(response, key.TIN).then((response) => {
				this.hash = response;
				this.setKey(key);
			});
		});
	},

	setESignKeys(userInfo, jsonData = "", type = "simple", _this) {
		jsonData = jsonData || userInfo.tin;
		this._this = _this;
		let key = this.datakeys.find((item) => {
			item.PIN = getPnfl(item.alias);
			return (
				(!item.isFizLitso ? item.TIN === userInfo.tin : item.PIN === userInfo.pin) && item.UID === userInfo.uid
			);
		});
		if (!key) {
			alert(i18n.t("e_sign_not_found"));
			return false;
		}
		if (type === "simple" || type === "timeStamp") {
			this.getKey(key).then((response) => {
				this.chekPassword(response, jsonData).then((response) => {
					this.hash = response;
					this.setKey(key);

					if (type === "timeStamp") {
						this.getTimeStamp(this.hash, this.selectData.dSKeySerial);
					}
				});
			});
		} else if (type === "append") {
			this.jsonData = jsonData;
			this.sign_append(key);
		} else {
			_this.getJSONHash(this, type);
		}
	},

	sign_append(vo) {
		if (vo.type === "certkey") {
			alert("Типы ключей не совпадают!");
		} else if (vo.type === "pfx") {
			let id = window.sessionStorage.getItem(vo.serialNumber);
			if (id) {
				this.postLoadKey_append(id, vo, function () {
					this.loadPfxKey(vo);
				});
			} else {
				this.loadPfxKey(vo);
			}
		}
	},

	loadPfxKey(vo) {
		let _this = this;
		CAPIWS.callFunction(
			{ plugin: "pfx", name: "load_key", arguments: [vo.disk, vo.path, vo.name, vo.alias] },
			function (event, data) {
				if (data.success) {
					let id = data.keyId;
					window.sessionStorage.setItem(vo.serialNumber, id);
					if (_this.errorCount > 2) {
						alert("Произошла ошибка");
						return;
					}
					_this.errorCount = (_this.errorCount || 0) + 1;
					_this.sign_append(vo);
				} else {
					alert(data.reason);
				}
			},
			function (e) {
				alert(e);
			}
		);
	},

	postLoadKey_append(id, vo, reload) {
		let _this = this;
		CAPIWS.callFunction(
			{ plugin: "pkcs7", name: "append_pkcs7_attached", arguments: [_this.jsonData, id] },
			function (event, data) {
				if (data.success) {
					_this.hash = data.pkcs7_64;
					_this.setKey(vo);
					_this.getTimeStamp(_this.hash, _this.selectData.dSKeySerial);
				} else {
					if (reload && data.reason === "Ключ по идентификатору не найден") {
						reload();
					} else {
						alert(data.reason);
					}
				}
			},
			function (e) {
				alert(e);
			}
		);
	},

	getESingKeyWithPromise(val, jsonData) {
		return new Promise((resolve) => {
			val = this.datakeys.find((item) => item.TIN === val);
			this.getKey(val).then((response) => {
				this.chekPassword(response, jsonData).then((response) => {
					this.setKey(val);
					resolve(response);
				});
			});
		});
	},
	handleCallbackFunction() {
		if (this.callbackFunction) {
			this.callbackFunction(...arguments);
		}
	},
	clearData() {
		this.datakeys = [];
		this.hash = null;
		this.callbackFunction = null;
		this.selectData = {
			id: 0,
			name: "",
			tin: "",
			businessCategory: "",
			region: "",
			district: "",
			dSKeyNumber: "",
			dSKeySerial: "",
			telefon: "",
			email: "",
			confirmPassword: "",
			userLastName: "",
			userFirstName: "",
			userMiddleName: "",
			userPosition: "",
			userInn: "",
		};
	},
};
export default ESign;
