import cyrl from "@/plugins/vee-validate/CyrlValidateMessage.json";

export default {
	notification: {
		error: "Хатолик",
		warning: "Огоҳлантириш",
		success: "Муваффақиятли",
	},
	validation: cyrl.messages,

	binary: "Бинарное",
	textual: "Текстовое",
	numeric: "Числовое",
	for: "За",
	against: "Против",

	methodsAuction: "Бошланғич нархни пасайтириш бўйича аукцион",
	test: "ТЕСТ",
	tashkent: "Тошкент",
	contacts: "Алоқа",
	address: "Манзил",
	workingHours: "Иш вақти",
	moreAboutServices: "Хизматлар ҳақида кўпроқ",
	submitApplication: "Иловани юбориш",
	bannerText: "Рақамли бизнесингиз учун комплекс ечимлар",
	whoAreWe: "Биз киммиз?",
	aboutText:
		"U Media 2021 йил январ ойида ташкил этилган ва қуйидаги хизматларни тақдим этади:\nРеклама конструкциялари ва LED экратларини лойиҳалаш, йиғиш, ўрнатиш ва доимий хизмат кўрсатиш.",
	whyAreWe: "Нега биз?",
	services: "Бизнесингизни ривожлантириш учун ҳамма нарса",
	getAnOffer: "Тижорат таклимизни кўриб чиқинг",
	partners: "Биз мижозларимиз ишончини қадрлаймиз",
	technologies: "Биз фойдаланидиган технологиялар",
	gallery: "Галерея",
	faq: "Саволлар",
	feedback: "Мулоҳазалар",
	company: "Корхона ҳақида",
	partnersMenu: "Ҳамкорлар",
	servicesMenu: "Хизматлар",
	vacancies: "Вакансиялар",
	copyrights: "Барча ҳуқуқлар ҳимояланган.",
	copyrightsInfo: "Ушбу сайтда ҳеч қандай маълумот оммавий таклиф эмас",
	yourName: "Исмингиз",
	yourPhoneNumber: "Телефон рақамингиз",
	yourEmail: "Электрон почта",
	captcha: "Расмдаги матнни киритинг",
	getOffer: "Таклиф олинг",
	error_404: "XATO 404",
	errorTitle: "Бу сиз излаётган нарса бўлмаслиги мумкин.",
	errorSubtitle:
		"Кечирасиз, сиз сўраган саҳифа мавжуд эмас. Манзилнинг ёзилишини текширинг ёки асосий саҳифага ўтинг.",
	backToHomePage: "Бош саҳифага қайтиш",
	errorText: "Нимадир нотўғри бажарилди",
	error: "Хато",
	success: "Муваффақиятли",
	messageSuccessfullySent: "Сизнинг хабарингиз муваффақиятли юборилди.",
	homeMainBannerTitle: "Ўзбекистон Республикаси Электрон кооперация портали",
	quickRegistration: "Дархол рўйхатдан ўтиш",
	startNow: "Ҳозироқ бошланг",
	callCenter: "Қўнғироқлар маркази",
	signIn: "Тизимга кириш",
	logOut: "Чиқиш",
	personalCabinet: "Шахсий кабинет",
	home: "Бош саҳифа",
	allSections: "Барча бўлимлар",
	procurement: "Харид қилиш",
	planGraphic: "Режа-жадвал",
	shop: "Электрон каталог",
	auction: "Аукцион",
	selection: "Танлов",
	tender: "Тендер",
	directContracts: "Тўғридан-тўғри харидлар",
	purchasingRange: "Харид қилиш диапазони",
	priceCriteria: "Нарх мезонлари",
	calculator: "Калькулятор",
	information: "Маълумотлар",
	statistics: "Статистика",
	standardFormsAndContracts: "Стандарт шартномалар ва шакллар",
	normativeActs: "Норматив ҳужжатлар",
	complaintsCommissionDecisions: "Шикоят комиссияси қарорлари",
	instructions: "Йўриқномалар",
	catalog: "Каталог",
	news: "Янгиликлар",
	allNews: "Барча янгиликлар",
	tariffs: "Тарифлар",
	subjectsOfProcurement: "Харид қилиш субъетклари",
	unifiedRegisterOfUnscrupulousPerformers: "Инсофсиз ижрочиларнинг ягона реестри",
	singleSupplierRegister: "Ягона етказиб берувчилар реестри",
	registerOfCorporateCustomers: "Корпоратив мижозлар реестри",
	registryOfBudgetCustomers: "Бюджет ташкилотлари реестри",
	supplierRegister: "Етказиб берувчилар реестри",
	specializedOrganizations: "Ихтисослашган ташкилотлар",
	operatorServices: "Оператор хизмати",
	videoInstruction: "Видео материаллар",
	purchasingMethods: "Сотиб олиш усуллари",
	suppliers: "Етказиб берувчилар",
	supplier: "Етказиб берувчи",
	location: "Яккасарой тумани, Шота Руставели кўчаси, 53В",
	inn: "СТИР",
	mfo: "МФО",
	checkingAccount: "Ҳисоб рақами",
	aboutPortal: "Портал ҳақида",
	publicOffer: "Оммавий оферта",
	formOfDocuments: "Ҳужжатлар шакли",
	technicalSupport: "Техник ёрдам",
	category: "Категория",
	commissionAmount: "Комиссия миқдори",
	summa: "Сумма",
	pledge: "Гаров",
	total: "Жами",
	days: "Кун",
	hours: "Соат",
	minutes: "Дақиқа",
	seconds: "Секунд",
	orderGlass: "Нархлар стакани",
	startingAmount: "Бошланғич нарх",
	currentAmount: "Жорий нарх",
	nextPrice: "Кейинги нарх",
	apply: "Аризани топшириш",
	biddingHistory: "Тендер тарихи",
	price: "Нарх",
	decrease: "Камайтириш",
	date: "Сана",
	time: "Вақт",
	name: "Номи",
	amountShort: "Миқдори",
	amount: "Миқдор",
	minimumAmount: "Минимал миқдор",
	unitShort: "Бирлик",
	description: "Тавсиф",
	estimatedDeliveryTime: "Тахминий етказиб бериш муддати",
	lotInformation: "Лот ҳақида маълумот",
	customerInformation: "Харидор ҳақида маълумот",
	filter: "Филтрлаш",
	search: "Қидириш",
	lotNumber: "Лот рақами",
	region: "Ҳудуд",
	chooseRegion: "Ҳудудни танланг",
	chooseCategory: "Категорияни танланг",
	clearFilter: "Филтрни тозалаш",
	cancel: "Бекор қилиш",
	show: "Кўрсатиш",
	searchByTaxNumber: "СТИР бўйича қидириш",
	organizationName: "Ташкилот номи",
	procedureType: "Жароёни тури",
	enterTheAmountOfTheCommercialOffer: "Тижорат таклифи миқдорини киритинг",
	selectThePercentageOfTheCollateral: "Гаров фоизини танланг",
	collateralAmount: "Гаров миқдори",
	theAmountOfOperatorServices: "Оператор хизматларининг миқдори",
	close: "Ёпиш",
	contest: "Аукцион",
	customer: "Харидор",
	provider: "Провайдер",
	contractNo: "Шартнома рақами",
	productName: "Маҳсулот номи",
	customerName: "Мижоз номи",
	workAndServices: "иш, хизматлар",
	contractAmount: "Шартнома миқдори",
	PerformerName: "Ижрочи номи",
	noData: "Маълумот йўқ",
	totalStartingCost: "Умумий бошланғич қиймати",
	calendarTerm: "Календар муддати",
	deliveryAddress: "Етказиб бериш манзили",
	contractNumber: "Шартнома рақами",
	contractDate: "Шартнома санаси",
	productDetail: "Маҳсулот деталлари",
	customerInn: "Харидорнинг СТИР рақами",
	customerRegion: "Харидор худуди",
	customerDistrict: "Харидор тумани",
	customerAddress: "Харидор манзили",
	customersPersonalAccountWithTheTreasury: "Харидорнинг Ғазначиликдаги шахсий ҳисоби",
	perforemersRegion: "Ижрочи вилояти",
	performersDistrict: "Ижрочи тумани",
	performersAddress: "Ижрочи манзили",
	performerInformation: "Ижрочи ҳақида маълумот",
	questionCategories: "Савол гуруҳлари",
	allSuppliers: "Барча етказиб берувчилар",
	voted: "Овоз берилди",
	signedContracts: "Имзоланган шартномалар",
	participants: "Иштирокчиларнинг",
	products: "Товарларнинг",
	perday: "Кунига",
	usefulLinks: "Фойдали ҳаволалар",
	enterProductNumberOrName: "Маҳсулотнинг номи ёки рақамини киритинг...",
	instructionSupplierRegistration: "Етказиб берувчи учун рўйхатдан ўтиш қўлланмаси",
	instructionSupplierAuction: "Етказиб берувчи учун аукцион қўлланмаси",
	instructionCustomerRegistration: "Харидорни рўйхатдан ўтказиш бўйича кўрсатма",
	instructionCustomerPlanGraphic: "Харидор режаси бўйича кўрсатма",
	instructionCustomerAuction: "Буюртмачи аукциони",
	instructionSupplierShop: "Етказиб берувчи учун дўкон қўлланмаси",
	instructionCustomerPlanSchedule: "Буюртмачи учун қўлланма (режа-жадвал)",
	instructionsCustomerAuction: "Буюртмачи учун қўлланма (аукцион)",
	instructionsCustomerShop: "Буюртмачи қўлланмаси дўкон",
	instructionsCustomerRegistration: "Буюртмачи учун қўлланма (рўйхатдан ўтиш)",
	instructionsCustomerBudget: "Маълумотнома юклаб олиш бўйича йўриқнома",
	instructionsCustomerBudgetAll:
		"Буджет ташкилотларининг рўйхатдан ўтиши, режа-жадвалларини қўшиши ва харид қилишлари учун ишлаб чиқилган йўриқнома",
	instructionService: "Иш ва хизматларни жойлаштириш бўйича йўриқнома",
	suppliersInstructionsAuction: "Иштирокчи учун қўлланма (аукцион)",
	suppliersInstructionsShop: "Иштирокчи қўлланмаси дўкон",
	suppliersInstructionsRegistration: "Иштирокчи учун қўлланма (рўйхатдан ўтиш)",
	instructionCustomerPlanScheduleSize: "97 kB",
	instructionsCustomerAuctionSize: "700 kB",
	instructionsCustomerShopSize: "1720 kB",
	instructionsCustomerRegistrationSize: "223 kB",
	suppliersInstructionsAuctionSize: "1177 kB",
	suppliersInstructionsShopSize: "1591 kB",
	suppliersInstructionsRegistrationSize: "160 kB",
	learnMore: "Батафсил маълумот",
	latestNews: "Сўнгги янгиликлар",
	publishedDate: "Нашр этилган сана",
	shareOnSocialMediaNetworks: "Ижтимоий тармоқларда баҳам кўринг",
	bargainingSubject: "Савдо мавзуси",
	month: "Ой",
	chooseYear: "Йилни танланг",
	year: "Йил",
	subjectOfTheAuction: "Аукцион мавзуси",
	action: "Амаллар",
	quarter: "Чорак",
	view: "Кўриш",
	priceCriteriaForPublicProcurement: "Давлат харидлари учун нарх мезонлари",
	subjectOfProcurement: "Харид қилиш мавзуси",
	budgetCustomers: "Бюджет мижозлари",
	corporateCustomers: "Корпоратив мижозлар",
	purchaseType: "Сотиб олиш тури",
	eShop: "Электрон каталог",
	product: "Маҳсулот",
	noLimitSet: "Чекланмаган",
	notLessThan5WorkingDays: "5 иш кунидан кам эмас",
	notLessThan30WorkingDays: "30 иш кунидан кам эмас",
	nameOfGoodsAllowedForPurchaseFromASingleSupplier:
		"Битта етказиб берувчидан сотиб олишга рухсат берилган товарлар (ишлар, хизматлар) номи",
	reason: "Сабаб",
	advancedSearch: "Кенгайтирилган қидирув",
	country: "Мамлакат",
	chooseCountry: "Мамлакатни танланг",
	manufacturer: "Ишлаб чиқарувчи",
	unitPrice: "Бирлик учун нарх",
	from: "дан",
	until: "гача",
	more: "Батафсил",
	expirationDate: "Тугаш муддати",
	showLess: "Камроқ кўрсатиш",
	showMore: "Кўпроқ кўрсатиш",
	orderName: "Буюртма номи",
	deadline: "Тугаш муддати",
	productQuantity: "Товар миқдори",
	showPeriod: "Даврни кўрсатиш",
	procurementInfoByRegion: "Минтақалар бўйича харидлар тўғрисидаги маълумотлар",
	transactionAmount: "Транзакция миқдори",
	saving: "Тежамкорлик",
	dealAmount: "Битимлар миқдори",
	top5SoldProductCategories: "Энг кўп сотиладиган 5 та маҳсулот тоифаси",
	categoryName: "Гуруҳ номи",
	selectionOfTheBestOffers: "Танлов",
	collateral: "Гаров",
	stateProcurements: "Давлат харидлари тўғрисида",
	ResolutionsOfThePresident: "Ўзбекистон Республикаси Президенти Қарорлари",
	LawsOfUzb: "Ўзбекистон Республикаси Қонунлари",
	DecreesOfThePresident: "Ўзбекистон Республикаси Президенти Фармонлари",
	provisions: "Қоидалар",
	docText4544:
		'Ўзбекистон Республикаси Президентининг  2019 йил 5 декабрдаги "Давлат харидлари тизимини янада такомиллаштириш ва давлат харидлари жараёнига тадбиркорлик субъектларини кенг жалб қилиш чора-тадбирлари тўғрисида" ги ПҚ-4544 сонли Қарори',
	docText3016:
		'"Харид қилиш тартиб-таомилларини ташкил этиш ва ўтказиш тартиби тўғрисидаги низомни тасдиқлаш ҳақида"ги Ўзбекистон Республикаси Президенти ҳузуридаги лойиҳа бошқаруви миллий агентлиги директорининг (Ўзбекистон Республикаси Адлия вазирлиги томонидан  2018 йил 26 майда 3016-сон билан рўйхатдан ўтказилган)',
	docText3015:
		'Ўзбекистон Республикаси Президенти ҳузуридаги лойиҳа бошқаруви миллий агентлиги директорининг "Махсус ахборот портали операторининг давлат харидларини ташкил этиш ҳамда ўтказиш борасидаги фаолияти тўғрисидаги низомни тасдиқлаш ҳақида"ги буйруғи  (Ўзбекистон Республикаси Адлия вазирлиги томонидан 2018 йил 21 майда 3015-сон билан рўйхатдан ўтказилган)',
	docText3013:
		'Ўзбекистон Республикаси Президенти ҳузуридаги лойиҳа бошқаруви миллий агентлиги директорининг "Давлат харидлари соҳасидаги шикоятларни кўриб чиқиш тартиби тўғрисидаги низомни тасдиқлаш ҳақида"ги буйруғи (Ўзбекистон Республикаси Адлия вазирлиги томонидан 2018 йил 14 майда 3013-сон билан рўйхатдан ўтказилган)',
	docText3057:
		'Ўзбекистон Республикаси Президенти ҳузуридаги лойиҳа бошқаруви миллий агентлиги директорининг "Давлат харидларини ўтказиш бўйича хизматларни кўрсатиш ҳуқуқига эга бўлган Ихтисослашган ташкилотлар тўғрисидаги низомни  тасдиқлаш ҳақида"ги буйруғи (Ўзбекистон Республикаси Адлия вазирлиги томонидан 2018 йил 20 августда 3057-сон билан рўйхатдан ўтказилган)',
	docText3953:
		"Ўзбекистон Республикаси Президентининг «Ўзбекистон Республикасининг «Давлат харидлари тўғрисида»ги Қонунини амалга ошириш чора-тадбирлари тўғрисида» ги Қарори 2018 йил 27 сентябрь, ПҚ-3953-сон",
	docTextAuctionContracts:
		'"Бошланғич нархни пасайтириш бўйича аукцион" платформасида электрон давлат харидлари натижалари бўйича товарлар етказиб бериш бўйича намунавий шартнома',
	docTextShopContracts:
		'"Электрон каталог" платформасида электрон давлат харидлари натижалари бўйича товарлар етказиб бериш бўйича товарлар етказиб бериш бўйича намунавий шартнома',

	servicesText3:
		"Хизмат банк мижозларига банк операцияларини бевосита ўзларининг «1С» ва «Didox» бухгалтерия тизимларидан амалга ошириш имконини беради.",
	servicesText4: "Биз ишончли етказиб берувчилар, товарлар сифати ва хавфсиз тўлов ҳақида қайғурамиз.",
	numberTrade: "Савдолар сони",
	tradingVolume: "Савдолар ҳажми",
	signInOrder: "Буюртма бериш учун киринг",
	requestPrice: "Нарх сўраш",
	count: "Сони",
	productDescription: "Товар таснифи",
	minMaxParty: "Мин/Макс партия",
	tradingUntilActive: "Савдо тугаш вақти",
	deliveryTime: "Етказиб бериш вақти",
	brand: "Марка",
	manufacturerCountry: "Ишлаб чиқарилган мамлакат",
	shelfLife: "Яроқлик муддати",
	warrantyPeriod: "Кафолат даври",
	warrantyTerms: "Кафолат шартлари",
	prodYearAndMonth: "Ишлаб чиқарилган йили ва ойи",
	techSpecifications: "Техник параметрлар",
	epidemiological: "Эпидемиологик хулоса",
	environmental: "Экологик экспертиза",
	autoRenewal: "Автоматик узайтириш",
	conclusions: "Ветеринария-санитария хулосаси",
	sertificate: "Сертификат мавжудлиги",
	phytosanitary: "Фитосанитария хулосаси",
	license: "Лицензиянинг мавжудлиги",
	veterinary: "Ветеринария хулосаси",
	nationalManufacturer: "Маҳаллий ишлаб чиқарувчи",
	deliveryRegion: "Етказиб бериш ҳудуди",
	deliveryRegions: "Етказиб бериш ҳудудлари",
	relativeProducts: "Ўхшаш товарлар",
	showAll: "Барчаси",
	request: "Сўров",
	protocolText01: "Танлов учун харид ҳужжатларини тасдиқлаш байённомаси",
	protocolText02: "Мувоифқлик байёни",
	protocolText03: "Танловни тиклаш байёни",
	protocolText04: "Танловни бекор қилиш байёни",
	protocolText05: "Танлов ғолибини аниқлаш байёни, балл учулида",
	protocolText06: "Танлов ғолибини аниқлаш байёни, энг арзон нарх усулида",
	protocolText07: "Танловнинг техник қисмини баҳолаш байёни",
	protocolText08: "Энг яхши таклифни тўхтатиб туриш байёни",
	protocolText09: "Тендер ўтказиш бўйича ҳисобот",
	protocolText10: "Тендер тиклаш бўйича байён",
	protocolText11: "Расмий талабларга мувофиқлигини текшириш байёни",
	protocolText12: "Тендер ғолибини аниқлаш байёни, балл усулида",
	protocolText13: "Тендер ғолибини аниқлаш байёни, энг арзон нарх усулида",
	protocolText14: "Тендерни бекор қилиш байёни",
	protocolText15: "Танловнинг малакавий қисмини баҳолаш байёни",
	protocolText16: "Тендер техник қисмини баҳолаш байёни",
	protocolText17: "Тендер тўхтатилганлиги тўғрисидаги байён",
	protocolText18: "Тендер ҳужжатларини тасдиқлаш байёни",
	modelContracts: "Намунавий шартномалар",
	typicalProtocols: "Намунавий байённомалар",
	fileInstruction: "Қўлланмалар",
	registrationQuestions: "Рўйхатдан ўтиш билан боғлиқ саволлар",
	scheduleQuestions: "Режа-жадвал билан боғлиқ саволлар",
	scheduleAuctions: "Аукцион билан боғлиқ саволлар",
	scheduleShops: "Дўкон билан боғлиқ саволлар",
	selectionQuestions: "Танлов билан боғлиқ саволлар",
	tenderQuestions: "Тендер билан боғлиқ саволлар",
	questionsPayment: "Тўловлар билан боғлиқ саволлар",
	questionsOperator: "Операторнинг комиссион хизматлари билан боғлиқ саволлар",
	popularQuestions: "Кўп бериладиган саволлар",

	otherDocuments: "Бошқа ҳужжатлар",
	select_all: "Барчасини танлаш",
	plan_schedule: "Режа-жадвали",
	incompleteTransactions: "Амалга ошмаган битимлар",
	end: "-------------------DO NOT PASS THIS LINE--------------------------",

	main_title: "Давлат харидларининг махсус ахборот портали",
	sourceOfFinancing: "Молиялаштириш манбаси",
	district: "Туман",
	deliveryConditions: "Етказиб бериш шарти",
	customerPhone: "Буюртмачи телефон",
	form_plan_schedule: "Режа жадваллари намуналарини шакллантириш",
	unifiedRegisterOfTheContract: "Шартномаларнинг ягона реестри",

	instructionCustomerSelectionTender: "Танлов ва тендер ўтказиш бўйича буюртмачи қўлланмаси",
	instructionSupplierSelectionTender: "Танлов ва тендер ўтказиш бўйича иштирокчи қўлланмаси",

	instructionCustomerSelectionTenderSize: "477kB",
	instructionSupplierSelectionTenderSize: "463kB",

	docTextEthical: "Одоб-аҳлоқ қоидалари",
	docTextEthicalSize: "40 kB",

	infact: "Факт бўйича",
	quarterly: "Чораклик",
	exactDate: "Аниқ сана",
	yes_or_no: "Ҳа/Йўқ",
	agree_or_disagree: "Розиман/Норозиман",
	critical: "Critical",
	preferably: "Афзал",
	systemic: "Тизимли",
	expert: "Эксперт томонидан",

	role: "Роль",
	clear: "Тозалаш",
	dealDate: "Битим муддати",
	selection_prepayment: "Олдиндан тўлов",
	selection_without_prepayment: "Олдиндан тўловсиз",
	selection_payment_order: "Тўлов тартиби",

	type: "Савдо тури",
	selectionQuatity: "Танлов",

	// new leng
	directContractsUpperCase: "Тўғридан тўғри шартномалар",
	otherTreatiesUpperCase: "Бошқа шартномалар",
	auctionUpperCase: "Аукцион",
	shopUpperCase: "Дўкон",
	selectionUpperCase: "Танлов",
	tenderUpperCase: "Тендер",
	nameOne: "Номланиши",
	tnVed: "ТИФ ТН",
	unitMeasurement: "Ўлчов бирлиги",
	gostStandards: "Давлат стандарти (ISO)",
	startDate: "Бошланиш муддати",
	endDate: "Тугаш вақти",
	importAlternativeRequest: "Импорт муқобил сўрови",
	priceFrom: "Нархи дан",
	priceTo: "Нархи гача",

	decreesofTheCabinetOFMinisters: "Вазирлар Маҳкамаси қарорлари",
	docText833:
		"Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2019 йил 30 сентябрдаги 833-сонли «Инвестициялар ва ташқи савдо вазирлиги ҳузуридаги рақамли трансформация марказининг электрон кооперация порталида ахборотни жойлаштириш ва харидларини амалга ошириш тартибини тасдиқлаш тўғрисида»ги Қарори",
	docText276:
		"Ўзбекистон Республикаси Вазирлар Маҳкамасининг 2022 йил 20 майдаги 276-сонли «Давлат харидларини амалга ошириш билан боғлиқ тартиб-таомилларни ташкил этиш ва ўтказиш тартиби тўғрисидаги низомни тасдиқлаш ҳақида»ги Қарори",
	supplier_1: "Ўзбекистон Республикаси Олий Мажлиси Сенати",
	supplier_2: "Ўзбекистон Республикаси Давлат Божхона Қўмитаси",
	supplier_3: "Ўзбекистон Республикаси Инвестициялар ва ташқи савдо вазирлиги",
	supplier_4: "Ўзбекистон Республикаси Давлат солиқ қўмитаси",
	supplier_5: "Ўзбекистон Республикаси Олий Мажлиси Қонунчилик Палатаси",
	feedback2: "Қайта алоқа",
	anAuction: "Аукцион",

	link_1: "Ўзбекистон Республикаси Олий Мажлиси Сенати",
	link_2: "Ўзбекистон Республикаси Иқтисодиёт ва молия вазирлиги ҳузуридаги Божхона қўмитаси",
	link_3: "Ўзбекистон Республикаси Инвестициялар, саноат ва савдо вазирлиги",
	link_4: "Ўзбекистон Республикаси Вазирлар Маҳкамаси  ҳузуридаги Солиқ қўмитаси",
	link_5: "Ўзбекистон Республикаси Олий Мажлиси Қонунчилик Палатаси",
	link_6: "Ўзбекистон Республикаси Иқтисодиёт ва молия вазирлиги",
	link_7: "Ўзбекистон Республикаси Президенти ҳузуридаги Статистика агентлиги",
	link_8: "Ўзбекистон техник жиҳатдан тартибга солиш агентлиги",

	supplier_1_title: "Навоий кон металлургия",
	supplier_2_title: "Узбекнефтегаз",
	supplier_3_title: "Алмалык кон металлургия",
	supplier_4_title: "AKFA",
	supplier_1_description:
		'"Навоий кон-металлургия комбинати" акциядорлик жамияти ("НКМК" АЖ) жаҳонда олтин ишлаб чиқарувчи йирик компаниялар ўнталигига киради. Комбинат ер ости бойликлари захираларини геологик қидириш, қазиб олиш ва қайта ишлашдан то тайёр маҳсулот олишгача бўлган ишлаб чиқариш жараёнлари тўлиқ амалга ошириладиган саноат кластеридир. Комбинат замонавий кон-транспорт ва технологик ускуналар, илғор технологиялар билан жиҳозлантирилган конлар, заводларга эга замонавий корхонадир.',
	supplier_2_description:
		"“Ўзбекнефтгаз” холдинг корпорацияси нефт ва газни қидириш, қазиб олиш, ташиш, сақлаш, қайта ишлаш ва сотиш билан шуғулланадиган Ўзбекистондаги энг йирик давлат компанияси.",
	supplier_3_description:
		"Ўзининг 70 йиллик тарихи давомида мамлакатимиз иқтисодий салоҳияти ривожига муносиб ҳисса қўшиб келаётган “Олмалиқ КМК” AЖ Ўзбекистондаги енг йирик саноат корхоналаридан бири бўлиб, унинг ишлаб чиқариш қуввати Тошкент, Жиззах, Наманган, Сурхондарё вилоятларида жойлашган мис-молибден, қўрғошин-рух, олтин ва кумуш конларига асосланади.",
	supplier_4_description: "111",

	listOfRecommended: "Тавсия этилган ҳужжатлар рўйхати",
	theVolumeOfIndustrial: "Саноат маҳсулотини ишлаб чиқариш ҳажми",
	proposalForCooperation: "Ҳамкорлик бўйича таклиф",
	inportAndExportVolme: "Импорт ва экспорт ҳажми",
	stateRealEstate: "Давлат кўчмас мулки",
	budgetPurtchases: "Бюджет харидлари: режа-жадваллар",
	corporateProcurement: "Корпоратив харидлар: режа-жадваллар",
	operatorDetailsForNon: "Бюджетдан ташқари ташкилотлар учун оператор реквизитлари",
	electronicCooperationPortal: "Ўзбекистон Республикаси Электрон кооперация портали",
	requisitesForBudgetary: "Бюджет ташкилотлари учун реквизитлар",
	specialist: "Воситачилик йиғими тўлови учун махсус ҳ/р",
	pr: "H/r",
	ls: "H/R",
	street: "кўча",
	phone: "Телефон рақами",
	eMail: "Почта манзили",
	electronicCooperationPortal3: "Электрон кооперация портали",
	sum: "сўм",

	pickup: "Олиб кетиш",
	generalCharacteristics: "Умумий хусусиятлар",
	numberOfBidsSubmitted: "Жойлаштирилган офeрталар сони",
	goodsOfOrganizationsRelatedPersonsWithDisabilities: "Ногиронлар жамиятига тегишли ташкилотларнинг товарлари",
	electricalEquipmentUzeltekhsanoat: "Электротехника маҳсулотлари (Ўзэлтехсаноат)",
	TNVEDCode: "ТИФ ТН коди",
	mechanismQuoteRequestAvailableForCustomers: "Нарх сўрови механизми Нарх сўрови буюртмачилар учун мавжуд",
	documentsTransferredWithTheGoods: "Товарлар билан бирга ўтказилган ҳужжатлар рўйхати",
	tvedCode: "TIF TN kodi",
	itemOfExpenses: "Харажатлар моддаси",
	totalAmount: "Умумий миқдор",
	pledgeAmount: "Закалат миқдори",
	commissionFee: "Воситачилик тўлови миқдори",
	totalBlockAmount: "Умумий банд миқдори",
	specifyQuantity: "Сонини киритинг",
	functionalCharacteristics: "Функционал хусусиятлар",
	cooperation: "Кооперация портали",
	cooperation_child: "Ривожланишда",
	pay: "Тўлаш",
	registerOfManufacturersDealers: "Ишлаб чиқарувчилар/дилерлар реестри",
	unPrePaid: "Олдиндан тўловсиз",
	prePaid: "Олдиндан тўлов",
	setPrePaidPercent: "Олдиндан тўлов фоизини белгиланг",
	notRequestPrice: "Сиз маҳсулотингиз учун нархни сўра олмайсиз",
	developing: "Ишлаб чиқилмоқда",
	poweredbyCDT: "Рақамли трансформация маркази",
	mechanismQuoteRequestNotAvailable: "Нарх сўрўви механизми ишлаб чиқилмоқда",
	onlineExhibitions: "Онлайн кўргазмалар",
	logIn: "Кириш",
	signUp: "Рўйхатдан ўтиш",
	totalDealAmount: "Битимларнинг умумий суммаси (миллион сўм)",
	minFt: "Ўзбекистон Республикаси Инвестициялар, саноат ва савдо вазирлиги",
	limitIsNotChecked: "Чеклов белгиланмаган",
	contractsCount: "Имзоланган шартномалар",
	contractsPrice: "Битимларнинг умумий суммаси (миллион сўм)",
	participantsCount: "Иштирокчилар",
	productsCount: "Товарлар",
	bannerText1: "Рақамли трансформация маркази",
	bannerText1Under: "Электрон давлат харидлари тизимининг оператори",
	bannerText2: "Ўзбекистон Республикаси Президентининг тадбиркорлар билан<br /> 'Очиқ мулоқоти' доирасида",
	bannerText2Under: "100 та лойиҳавий таклифлар",
	bannerText5: "Маҳсулотлар каталоги",
	bannerText5Under: "2024 йилда ўтказилган Республика тармоқлараро саноат ярмаркаси маҳсулотлари каталоги",
	yearly: "Йилда",
	aboutPortalTitle:
		"Портал Ўзбекистон Республикаси Президентининг “Саноат кооперациясини янада ривожлантириш ва талаби юқори маҳсулотлар ишлаб чиқаришни кенгайтириш чора-тадбирлари тўғрисида”ги 2019 йил 1-майдаги ПҚ-4302-сонли Қарори ва Ўзбекистон Республикаси Вазирлар Маҳкамасининг “Саноат ярмаркалари ва электрон кооперация биржаси фаолиятини ташкил этиш чора-тадбирлари тўғрисида”ги 2019 йил 30 сентябрдаги 833-сон қарорига мувофиқ ишлаб чиқилган.",
	aboutPortalSubtitle:
		"Электрон кооперация портали - бу маҳаллий ишлаб чиқарувчилар ўртасида саноат соҳасидаги кооперация алоқаларини мустаҳкамлаш ва ривожлантириш, саноат маҳсулотлари ишлаб чиқаришни кенгайтириш ва кичик бизнес субъектларини саноат фаолиятига кенг жалб қилиш мақсадида яратилган махсус савдо майдончасидир.",
	testingMode: "Веб-сайт ишлаб чиқилмоқда",
	instructionForUsers: "Рўйхатдан ўтиш",
	instructionForProducts: "Маҳсулот жойлаштириш йўриқномаси",
	amountOfDeal: "Шартнома суммаси",
	executor: "Ижрочи",
	periodOfExecution: "Бажариш муддати",
	startingPrice: "Бошланғич нархи",
	protocols: "Протоколлар",
	dealStatus: "Битим ҳолати",
	sourceFunded: "Манба молиялаштирилган",
	numberOfAddedItems: "Қўшилган элементлар сони",
	participantType: "Иштирокчи тури",
	manufacturerDealer: "Ишлаб чиқарувчи/дилер",
	dealer: "Дилер",
	calculate: "Ҳисоблаш",
	myFilters: "Менинг фильтрларим",
	startPrice: "Жараённинг бошланғич нархи",
	onlineFair: "Ярмарка",
	authForVote: "Овоз бериш учун рўйҳатдан ўтинг",
	timerText: "Савдолар бошланиш вақти: 03.07.2023 9:00",
	instructionForCert: "Сертификатлар қўшиш йўриқномаси",
	instructionForDiler: "Дилерлик қўшиш йўриқномаси",
	day2: "Кун",
	day3: "Кун",
	day4: "Кун",
	certificated: "Сертификатланган",
	notCertificated: "Сертификатланмаган",
	inAYear: "Йилига",
	titleOfWithoutPercent: "Шартноманинг намунавий шакли (Электрон каталог, тўлиқ тўлов)",
	titleOfWithPercent: "Шартноманинг намунавий шакли (Электрон каталог, олдиндан тўлов)",
	eSignKeyError: "Парол нотўғри киритилди",
	instructionForCellWithoutPrePayment: "Савдо қилиш жараёни йўриқномаси (олдиндан тўловсиз)",
	instructionForRejectingOrEnablingCell: "Тўловларни амалга ошириш ва шартномаларни бекор қилиш йўриқномаси",
	inDevelopingMode: "Ишлаб чиқилмоқда",
	abrobation: "Сайт на стадии апробации",
	questionnaire: "Сўровнома",
	gotoQuestionaree: "Сўровномани тўлдиринг",
	typeOfPayment: "Тўлов тури",
	artisans: "Ҳунармандлар томонидан ишлаб чиқарилган маҳсулотлар",
	bannerText4:
		"12-15-декабр кунлари Тошкент шаҳридаги “Ўзекспомарказ” Миллий кўргазмалар мажмуасида йил давомида ҳудудларда ўтказиб келинган саноат ярмаркаларининг якуний босқичи - Республика тармоқлараро саноат ярмаркаси бўлиб ўтмоқда.",
	registerOfActiveTrades: "Фаол савдолар реестри",
	numberOffer: "Оферта рақами",
	maxAmount: "Максимал миқдор",
	allActiveTrades: "Барча актив савдолар",
	ourNews: "Aфзаллик ва қулайликлар",
	titleOfFeatutures:
		"Рақамли трансформация марказининг Электрон кооперация порталида жорий қилинган афзаллик ва қулайликлари",
	feature1:
		"Савдо жараёнларида қатнашган иштирокчиларнинг шаҳсий кабинетида савдо тугагандан сўнг <b>ғолиб чиқган иштирокчини кўриш имкони</b> яратилди.",
	feauture2Title: "<b>Сўровларни</b> қуйидаги қисмларга ажратилди:",
	feature2Text1: "Барча сўровлар;",
	feature2Text2: "Менинг сўровларим (фаол сўровлар);",
	feauture2FinalText: "Шунингдек, барча сўровлар ва хабарномаларни маълумотлар базасига сақлаш вазифаси бажарилди.",
	feauture3Title:
		"Савдо жараёни тугагандан сўнг ушбу савдодаги <b>тўлиқ тарихини</b> кўриш имкони яратилди, жумладан:",
	feauture3Text1: "сўров қачон юборилган;",
	feauture3Text2: "сўров қачон қабул қилинган;",
	feauture3Text3: "савдо бошланган ва тугаш вақти;",
	feauture3Text4: "савдога хабарнома олган иштирокчилар рўйхати;",
	feauture3Text5: "иштирокчиларнинг савдодаги берган нарх таклифлари, миқдори ва вақти;",
	feauture3Text6: "Савдода ғалаба қозонган иштирокчининг маълумотлари.",
	feauture4:
		"Порталда тузилган <b>шартномалар реестрларини шакллантириш ва юритиб бориш</b> механизми такомиллаштирилди.",
	feauture5:
		"Шартнома шаклланган кундан бошлаб буюртмачи томонидан тўлов амалга оширилмаса буюртмачидан <b>18 банк кунидан</b> кейин <b>жарима ундириш</b> ва <b>шартномани бекор қилиш</b> функцияси жорий қилинди.",
	feauture6: "Сертификат талаб қилинадиган <b>кодларни тоифаларга ажратиш</b> вазифаси бажарилди, (шарт/шарт эмас).",
	feauture7:
		"ТИФ ТН коднинг бошидаги 4 та рақами бирхил бўлган тақдирда, савдо бошланганлиги ҳақида <b>СМС-хабарнома</b> юбориш функцияси жорий қилинди.",
	feauture8:
		"Сертификат ва дилерлик муддати тугаганга қадар ёки  фаол оферта коди бўйича импорт аниқланганда <b>хабарнома бериш функцияси</b> жорий қилинди.",
	feauture9: "Шартномани бекор қилинганда <b>жарима тафсилотларини</b> кўрсатиш имкони яратилди.",
	feauture10Title: "Оферталарнинг модерациядан ўтиш тартиби <b>рақамлаштирилди</b>, яъни:",
	feauture10Text1: "Барча келиб тушган оферталар навбати билан ўтказилиши;",
	feauture10Text2:
		"Портал фойдаланувчилари шахсий кабинети орқали ўз маҳсулотининг модерациядан ўтиш навбатини доимий мониторинг қилиб бориш имкони яратилди;",
	feauture10Text3:
		"Агар навбатсиз ҳолда оферта модерациядан ўтган тақдирда ҳам, мазкур оферта дарҳол фаол бўлмайди, ўз навбати келганда тизим автоматлаштирилган ҳолда фаол ҳолатига ўтказади.",
	feauture10FinalText:
		"Модерацияга навбат механизми: агар навбатда бошқа оферталар мавжуд бўлса оферта маъқуллангандан сўнг дарҳол фаол бўлмайди, кутиш режимига ўтказилади.",
	feauture11:
		"Савдо жараёнидаги шаффофликни таъминлаш мақсадида, Ҳисоб палатаси, Рақобатни ривожлантириш ва истеъмолчилар ҳуқуқларини ҳимоя қилиш қўмитаси ҳамда  Монополияга қарши курашиш қўмитаси билан биргаликда ўзаро <b>маълумот алмашинуви (интеграция)</b> ишлари амалга оширилди.",
	feauture12:
		"Портал фойдаланувчилари учун <b>реал вақт режимида жорий савдолар рўйхати ва маълумотларини кўриб бориш</b> имконияти яратилди.",
	readTheFeautures: "Танишиб чиқдим",
	notificationText: "Xabarnoma",
	support: "Қўллаб-қувватлаш",
	code2022: "ТИФ ТН Кодлари (2022)",
	offerUntilActive: "Оферта фаоллигининг тугаш вақти",
	cooperAdress: "100027, Тошкент шаҳар, Шайхонтоҳур, Қоратош кўчаси, 2А",
	socialMedia: "Ижтимоий тармоқлар",
	typeSms: "Телефон рақамизга юборилган кодни киритинг",
	send: "Юбориш",
	comment: "Изоҳ",
	participantAccountInStore: "Мижознинг жамғармадаги ҳисоб рақами",
	monthCountOfPayment: "Тўлов қилиш ойи",
	selectRegion: "Илтимос, ҳудудни танланг",
	fullSearchFromList: "Маълумотнома олиш учун кенгайтирилган қидирув",
	getInformation: "Маълумотнома олиш",
	typeFirst3LettersOfProduct: "маҳсулотнинг бошидаги 3 та ҳарфини ёзинг",
	typeFirst3LettersOfCode: "ТИФ ТН кодининг бошидаги 4 та рақамини ёзинг",
	pictureOfItem: "Товар расми",
	rejectAnswer1: "Товар (иш, хизмат)нинг мавжуд эмаслиги",
	rejectAnswer2: "Товар (иш, хизмат) миқдорининг камлиги ёки етарли эмаслиги",
	rejectAnswer3: "Товар (иш, хизмат)нинг сифати, кафолати, техник параметрлари талаб даражасига мувофиқ келмаслиги",
	rejectAnswer4: "Етказиб бериш муддатининг узоқлиги",
	rejectAnswer5: "Бошқа сабаблар",
	rejectionAnnouncement:
		"Товарлар (ишлар, хизматлар)ни маҳаллий ишлаб чиқарувчилардан харид қилиш имкониятини мажбурий тартибда ўрганиш талабларига риоя қилмаслик тегишли жавобгарликка сабаб бўлиши мумкин.",
	productNameMin: "Товар номи 3 та ҳарфдан кам бўлмаслиги керак",
	sendedRequestToDMBAt: "Сўров ДМБAТга юборилди",
	restoringTitle: "ДИҚҚАТ ЭЪЛОН !!!",
	restoringText: "Порталда техник ишлар олиб борилмоқда, портал қисқа муддатда ишга тушишини маълум қиламиз.",
	notFound: "Сизнинг сўровингиз бўйича ҳеч қандай натижа топилмади",
	loginForInfo: "Маълумотнома олиш учун киринг",
	goToPersonalCabinet: "Шахсий кабинетга ўтиш",
	registerOfSingleBusnises: "Ягона етказиб берувчилар реестри",
	tableSupllierName: "Товар (иш, хизмат) етказиб берувчи номи",
	tableSupllierType: "Товар (иш, хизмат) номи",
	statusName: "Ҳолати",
	codeOfKlassifikator: "Классификатор коди",
	dateOfSupplierIn: "Реестрга киритилган сана",
	purpose: "Aсос",
	buxoroTopProducts: "Бухоро вилояти топ маҳсулотлари",
	numberOfActiveOffers: "Aктив оферталар сони",
	disabilityCompanyText:
		"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0 Ҳурматли мижоз, Сиз танлаган оферта Ногиронлар жамиятига тегишли ташкилотнинг товарлари ҳисобланади, ушбу товар бўйича порталда тўғридан-тўғри савдо бўлиб ўтишини маълум қиламиз.",
	numberOfOffers: "Оферталар сони",
	active: "Aктив",
	all: "Барчаси",
	registrationDate: "Рўйхатдан ўтган сана",
	greenShopping: "Яшил харидлар",
	budget: "Бюджет",
	typeOrganization: "Ташкилот тури",
	getAllCompanies: "Рўйхатдан ўтган ташкилотлар",
	numberOfOffersByCorp: "Ишлаб чиқарувчи оферталар сони",
	numberOfOffersByDiler: "Дилер оферталар сони",
	corporative: "Корпоратив",
	providerOrCustomer: "Ташкилот ҳолати",
	customer2: "Буюртмачи",
	direct_contract: "Тўғридан-тўғри шартнома",
	next: "Кейинги",
	back: "Олдинги",
	enktCode: "МСК коди",
	epFindings: "Эпидемиологик хулосалар",
	verOp: "Ветеринария хулосалар",
	phyConc: "Фитосанитария хулосалар",
	vetSan: "Ветеринария санитария хулосалар",
	enAssessment: "Экологик экспертиза",
	no: "Йўқ",
	yes: "Ҳа",
	thereIs: "Мавжуд",
	instructionGovernment: "Маҳсулот кўшиш учун мурожаат юбориш бўйича йўриқнома",
	goods: "Маҳсулотлар",
	work_and_services: "Ишлар ва хизматлар",
	enter_name: "Исмингизни киритинг",
	phone_number: "Телефон рақам",
	enter_phone_number: "Телефон рақамингизни киритинг",
	your_email: "E-Mail манзилингизни киритинг",
	registerOfDirectTrades: "Тўғридан-тўғри савдолар реестри",
	ordinalNumber: "Т/р",
	organizationNameCustom: "Ташкилот номи (буюртмачи)",
	orderingTin: "Буюртмачи СТИР маълумоти",
	companyNameSupplier: "Ташкилот номи (етказиб берувчи)",
	suplierTin: "Етказиб берувчи СТИР маълумоти",
	addComment: "Изоҳ қолдириш",
	companyComment: "Изох қолдирган корхона номи",
	companyCommentTin: "Изоҳ қолдирган корхона СТИР маълумоти",
	comments: "Изоҳлар",
	commentAdded: "Изоҳ қўшилди",
	errorOccurred: "Хатолик юз берди",
	commentRequirements:
		"Эслатиб ўтамиз! Шахснинг шаъни ва қадр-қимматини камситувчи шарҳлар ҳамда асоссиз ва ёлғон маълумот тарқатган тегишли тартибда маъмурий (МЖтК 41-модда) ва жиноий (ЖК 140-модда) жавобгарликка тортилишига сабаб бўлими мумкин!",
	fullName: "Ф.И.Ш",
	pleaseRegister: "Изоҳ қолдириш учун, илтимос рўйҳатдан ўтинг.",
	add: "Қўшиш",
	warning: "Диққат",
	allProductsInPortal: "Порталга жойлаштирилган барча маҳсулотлар",
	allServicesInPortal: "Порталга жойлаштирилган барча иш, хизматлар",
	moderationProducts: "Барча маҳсулотлар",
	moderationProductsDealer: "Шундан дилерлар",
	activeProducts: "Актив маҳсулотлар",
	moderationServices: "Барча иш, хизматлар",
	activeServices: "Актив иш, хизматлар",
	soonStartLaw: 'Вазирлар Маҳкамасининг “2025-2027 йилларда давлат харидлари тизимини такомиллаштириш ва ривожлантириш стратегиясини тасдиқлаш тўғрисида”ги 2024 йил 20 декабрдаги 865-сонли қарори билан 2025 — 2027 йилларда давлат харидлари тизимини такомиллаштириш ва ривожлантириш стратегиясини амалга ошириш бўйича чора-тадбирлар режаси тасдиқланган. Мазкур режада давлат харидлари электрон тизими операторларига харид тартиб-таомилларини барча турларини ўз электрон тизимларида жорий этиш вазифаси юклатилган. Қарор ижросини самарали таъминлаш мақсадида Сизга Электрон кооперация порталида ушбу модул ишга тушириш босқичида эканлигини маълум қиламиз.',
	importProducts: "Импорт маҳсулотлар"
};
