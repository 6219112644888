<template>
	<div class="countDownWrapper">
		<MainSwiper class="bannerSlider" :slidesPerView="1" :data="listOfElems" :breakpoints="breakpoints">
			<template #default="{ item }">
				<div class="swiper-item">
					<img :src="item.image" alt="not image" />
					<div style="margin: 0 auto" :style="{ width: item.width || '100%' }">
						<h3 v-html="$t(item.text)" :style="{ 'font-size': item.font || '38px' }" />
						<template v-if="item.subtext">
							<p v-html="$t(item.subtext)"></p>
						</template>
						<template v-if="item.link">
							<a class="customClassforLinkInBlank" :href="item.link" target="_blank">{{ $t("more") }}</a>
						</template>
					</div>
				</div>
			</template>
		</MainSwiper>
		<!-- <section class="container countdownFixed flexCenter">
			<finaltimer />
		</section> -->
	</div>
</template>
<script>
import { Swiper, SwiperSlide, SwiperCore } from "swiper-vue2";
import { Navigation, Pagination } from "swiper";

import "swiper/swiper-bundle.css";
import MainSwiper from "../../../ui/MainSwiper.vue";
import Finaltimer from "../../../finaltimer.vue";

SwiperCore.use([Navigation, Pagination]);
export default {
	components: {
		Swiper,
		SwiperSlide,
		MainSwiper,
		Finaltimer,
	},
	data() {
		return {
			breakpoints: {
				1: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
			},
			listOfElems: [
				// {
				// 	image: require("@/assets/images/banner/005.png"),
				// 	text: "bannerText4",
				// 	font: "28px",
				// 	width: "80%",
				// },
				{
					image: require("@/assets/images/banner/000.jpg"),
					text: "bannerText1",
					subtext: "bannerText1Under",
				},
				{
					image: require("@/assets/images/banner/005.jpg"),
					text: "bannerText5",
					subtext: "bannerText5Under",
					link: "https://yarmarka.cooperation.uz/catalog/2024",
				},
				// {
				// 	image: require("@/assets/images/banner/001.jpg"),
				// 	text: "bannerText2",
				// 	subtext: "bannerText2Under",
				// 	link: "https://loyiha.miit.uz/",
				// },
				// {
				// 	image: require("@/assets/images/banner/002.jpg"),
				// 	text: "bannerText3",
				// 	subtext: "bannerText3Under",
				// },
				{
					image: require("@/assets/images/banner/003.jpg"),
					text: "bannerText1",
					subtext: "bannerText1Under",
				},
			],
		};
	},
};
</script>
<style lang="scss" scoped>
.customClassforLinkInBlank {
	text-decoration: underline;
	font-size: 1.3rem;
}
::v-deep .swiper-container {
	height: 100% !important;
}
.bannerSlider {
	position: relative;
	height: 100%;
}
.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}
.countdownFixed {
	position: absolute;
	bottom: 10%;
	left: 50%;
	transform: translateX(-50%);
	z-index: 9;
}

.countDownWrapper {
	position: relative;
	height: 43vh;
}
.swiper-item {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 2;
	&::before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: "";
		z-index: 1;
		background: rgba(0, 0, 0, 0.3);
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
	}
	div {
		position: relative;
		z-index: 100;
		width: 100%;
		top: 0;
		height: 100%;
		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
		gap: 30px;
		color: white;
		h3 {
			font-size: 38px;
		}
		p {
			font-size: 28px;
		}
	}
}
@media screen and (max-width: 768px) {
	.countDownWrapper {
		height: 35vh;
	}
	.countdownFixed {
		bottom: 20%;
	}
	.swiper-item {
		div {
			gap: 10px;
			h3 {
				font-size: 16px;
			}
			p {
				font-size: 12px;
			}
		}
	}
}
</style>

