<template>
	<div :class="{open: isChatOpen}">
			<div class="overlay-chat">
				<ChatOpenner :local-is-chat-open="isChatOpen" @toggleChat="toggleChat" />
				<button v-if="isChatOpen" class="closer-btn" @click="toggleChat">
					<svg xmlns="http://www.w3.org/2000/svg" width="2.29em" height="1em" viewBox="0 0 16 7"><path fill="currentColor" d="M8 6.5a.47.47 0 0 1-.35-.15l-4.5-4.5c-.2-.2-.2-.51 0-.71s.51-.2.71 0l4.15 4.15l4.14-4.14c.2-.2.51-.2.71 0s.2.51 0 .71l-4.5 4.5c-.1.1-.23.15-.35.15Z"/></svg>
				</button>
			<div class="chat-iframe-container" ref="iframeContainer">

			</div>
			</div>

		<div id="app">
			<router-view :key="$store.getters.defineSelectionOrTender($route.path)" />
			<notification />
		</div>
		<Loading />
		<!-- <Restoring /> -->
		<!-- <Snowlfake /> -->
	</div>
</template>
<script>
import Notification from "@/components/shared-components/Notification";
import Loading from "./components/loading.vue";
import Snowlfake from "./components/winter/snowflake";
import Restoring from "./components/pages/Restoring";
import ChatOpenner from "@/components/ChatOpenner.vue";
import TokenService from "@/services/TokenService";

export default {
	name: "app",
	components: {ChatOpenner, Notification, Loading, Snowlfake, Restoring },
	data() {
		return {
			isChatOpen: false,
		}
	},
	async created() {
		await this.getServerTime();
		await this.getWorkingTime();
		this.changeSavedTimeWithInterval();
		this.getServerTimeWithInterval();
	},
	mounted() {
		// const iframe = document.getElementById('myIframe'); // iframe element
		// iframe.contentWindow.postMessage(
		// 	{ token: localStorage.getItem('Authorization') || false,
		// 		refreshToken: localStorage.getItem('Refresh') || false}, // Data to send
		// 	'http://localhost:3000'// Target origin
		// );
	},
	methods: {
		toggleChat() {
			this.isChatOpen = !this.isChatOpen;
			const iframe = document.getElementById('myIframe'); // iframe element

			if(iframe && this.isChatOpen) {
				this.initializeTokensForChat()
			} else if(!iframe) {
				this.insertIframe(() => {
					setTimeout(() => {
						this.initializeTokensForChat(); // Initialize tokens after iframe is loaded
					}, 800)
				});
			}
		},
		initializeTokensForChat() {
				const iframe = document.getElementById('myIframe'); // iframe element
				iframe.contentWindow.postMessage(
					{ token: localStorage.getItem('Authorization') || false,
						refreshToken: localStorage.getItem('Refresh') || false}, // Data to send
					process.env.VUE_APP_TARGET_ORIGIN// Target origin
				);
		},
		insertIframe(onLoadCallback) {
			const iframe = document.createElement("iframe");
			iframe.id = "myIframe";
			iframe.loading = "lazy";
			iframe.src = `${process.env.VUE_APP_TARGET_ORIGIN}${TokenService.getStructureId() === '1' ? '/uz/moderator/auth' : '/uz/client/auth'}`;
			iframe.className = "iframe-self";
			iframe.title = "Chat Application";

			// Add a load event listener to ensure iframe is ready before sending messages
			iframe.onload = () => {
				if (onLoadCallback) {
					onLoadCallback();
				}
			};

			this.$refs.iframeContainer.appendChild(iframe);
		},
		changeServerTime() {
			if (!this.$store.getters.getServerTime) {
				return;
			}
			let serverTime = this.$store.getters.getServerTime;
			let newServerTime = this.$moment(new Date(serverTime)).add(1, "s");
			this.$store.commit("setServerTime", newServerTime);
		},
		changeSavedTimeWithInterval() {
			setInterval(() => {
				this.changeServerTime();
			}, 1000);
		},
		getServerTime() {
			return this.$api.get("/api-client/Client/GetByDateTime").then((response) => {
				this.$store.commit("setServerTime", response.data.result.dateTimes);
			});
		},
		getServerTimeWithInterval() {
			setInterval(() => {
				this.getServerTime();
			}, 1000 * 60 * 5);
		},
		async getWorkingTime() {
			if (localStorage.Authorization) {
				this.$store.commit("setLoadingStatus", true);
				const response = await this.$api.get("/api-shop/LotRequest/GetTimeCheck");
				console.log(response.data.result);
				this.$store.commit("hybridSetter", {
					key: "isWorkingHour",
					data: response.data.result.isWorkingHours,
				});
				this.$store.commit("hybridSetter", {
					key: "isWorkingDay",
					data: response.data.result.isWorkingDay,
				});
				this.$store.commit("setLoadingStatus", false);
			}
		},
	},
};
</script>

<style>
.closer-btn {
	position: absolute;
	right: 40px;
	bottom: 740px;
	z-index: 9999999999999;
}

.open #myIframe {
	opacity: 1;
	visibility: visible;
	width: 460px;
	height: 800px;
}


.overlay-chat {
	right: 0;
	bottom: 0;
	width: 140px;
	height: 140px;
	position: fixed;
	z-index: 150;
}

.open .overlay-chat {
	width: 100vw;
	height: 100vh;
	background: rgba(0,0,0, 0.4);
}

.chat-iframe-container {
	width: 100%;
	max-width: 1200px; /* Adjust as per your layout */
	margin: 0 auto;
	position: relative;
}
.iframe-self {
	width: 0;
	height: 0;
	border: none;
	position: fixed;
	z-index: 200;
	right: 0;
	bottom: 0;
}
</style>
