import ru from "@/plugins/vee-validate/RuValidateMessage.json";

export default {
	notification: {
		error: "Ошибка",
		warning: "Предупреждение",
		success: "Успешно",
	},
	validation: ru.messages,

	methodsAuction: "Аукцион на понижение стартовой цены",
	test: "ТЕСТ",
	tashkent: "Ташкент",
	contacts: "Контакты",
	address: "Адрес",
	workingHours: "Режим работы",
	moreAboutServices: "Подробнее об услугах",
	submitApplication: "Оставить заявку",
	bannerText: "Комплексные решения для вашего бизнеса в сфере Digital",
	whoAreWe: "Кто мы?",
	whyAreWe: "Почему мы?",
	services: "Всё для развития вашего бизнеса",
	getAnOffer: "Ознакомьтесь с нашим коммерческим предложением",
	partners: "Мы ценим доверие наших клиентов",
	technologies: "Технологии которые мы используем",
	gallery: "Галерея",
	faq: "Вопросы",
	feedback: "Отзывы",
	company: "О компании",
	partnersMenu: "Партнеры",
	servicesMenu: "Услуги",
	vacancies: "Вакансии",
	copyrights: "Все права защищены.",
	copyrightsInfo: "Никакая информация на данном сайте не является публичной офертой",
	yourName: "Ваше имя",
	yourPhoneNumber: "Ваш номер",
	yourEmail: "Ваша почта",
	captcha: "Введите текст с изображения",
	getOffer: "Получить предложение",
	error_404: "ОШИБКА 404",
	errorTitle: "Возможно это не то, что вы искали.",
	errorSubtitle:
		"Извините, запрашиваемая вами страница не существует. Проверьте правильность написания адреса или перейдите на главную страницу.",
	backToHomePage: "Вернуться на главную страницу",
	errorText: "Что-то пошло не так",
	error: "Ошибка",
	success: "Успешно",
	messageSuccessfullySent: "Ваше сообщение было успешно отправлено.",
	homeMainBannerTitle: "Электронный кооперационный портал Республики Узбекистан",
	quickRegistration: "Быстрая регистрация",
	startNow: "Начать сейчас",
	callCenter: "Call центр",
	signIn: "Войти",
	signUp: "Регистрация",
	logOut: "Выйти",
	personalCabinet: "Личный кабинет",
	home: "Главная",
	allSections: "Все разделы",
	procurement: "Закупки",
	planGraphic: "План-график",
	shop: "Электронный каталог",
	auction: "Аукцион",
	selection: "Отбор",
	tender: "Тендер",
	directContracts: "Прямые договора",
	purchasingRange: "Диапазон закупок",
	priceCriteria: "Ценовые критерии",
	calculator: "Калькулятор",
	information: "Информация",
	statistics: "Статистика",
	standardFormsAndContracts: "Типовые формы и договоры",
	normativeActs: "Нормативные акты",
	complaintsCommissionDecisions: "Решения комиссии по рассмотрению жалоб",
	instructions: "Инструкции",
	catalog: "Каталог товаров",
	news: "Новости",
	allNews: "Все новости",
	tariffs: "Тарифы",
	subjectsOfProcurement: "Субъекты закупок",
	unifiedRegisterOfUnscrupulousPerformers: "Единый реестр недобросовестных исполнителей",
	singleSupplierRegister: "Реестр единых поставщиков",
	registerOfCorporateCustomers: "Реестр Корпоративных заказчиков",
	registryOfBudgetCustomers: "Реестр бюджетных заказчиков",
	supplierRegister: "Реестр поставщиков",
	specializedOrganizations: "Специализированные организации",
	operatorServices: "Сервисы оператора",
	videoInstruction: "Видео инструкция",
	purchasingMethods: "Способы закупок",
	suppliers: "Поставщики",
	supplier: "Поставщик",
	location: "Чиланзар-2, улица Арнасой,76",
	inn: "ИНН",
	mfo: "МФО",
	checkingAccount: "Расчетный счет",
	aboutPortal: "О портале",
	publicOffer: "Публичная оферта",
	formOfDocuments: "Форма документов",
	technicalSupport: "Техническая поддержка",
	category: "Категория",
	commissionAmount: "Комиссионная сумма",
	totalAmount: "Общая сумма",
	pledgeAmount: "Залоговая сумма",
	commissionFee: "Комиссионный сбор",
	totalBlockAmount: "Общая сумма блокировки",
	summa: "Сумма",
	pledge: "Залог",
	total: "Итого",
	days: "Дней",
	hours: "Часов",
	minutes: "Минут",
	seconds: "Секунд",
	orderGlass: "Стакан заявок",
	startingAmount: "Стартовая сумма",
	currentAmount: "Текущая сумма",
	nextPrice: "Следующая цена",
	apply: "Подать заявку",
	biddingHistory: "История подачи ставок",
	price: "Цена",
	decrease: "Снижение",
	date: "Дата",
	time: "Время",
	name: "Наименование",
	amountShort: "Кол-во",
	amount: "Количество",
	minimumAmount: "Мин. кол-во",
	unitShort: "Ед.Изм.",
	description: "Описание",
	estimatedDeliveryTime: "Срок доставки",
	lotInformation: "Информация о лоте",
	customerInformation: "Информация о заказчике",
	filter: "Фильтр",
	search: "Поиск",
	lotNumber: "№ Лота",
	region: "Регион",
	chooseRegion: "Выберите регион",
	chooseCategory: "Выберите категорию",
	clearFilter: "Очистить фильтр",
	cancel: "Отмена",
	show: "Показать",
	searchByTaxNumber: "Показать",
	organizationName: "Наименование организации",
	procedureType: "Тип процедуры",
	enterTheAmountOfTheCommercialOffer: "Введите сумму коммерческого предложения",
	selectThePercentageOfTheCollateral: "Выберите процент залоговой суммы",
	collateralAmount: "Сумма залога",
	theAmountOfOperatorServices: "Сумма услуг оператора",
	close: "Закрыть",
	contest: "Конкурс",
	customer: "Заказчик",
	provider: "Поставщик",
	contractNo: "№ договора",
	productName: "Наименование товара",
	customerName: "Наименование заказчика",
	workAndServices: "работ, услуг",
	contractAmount: "Сумма договора",
	PerformerName: "Наименование исполнителя",
	noData: "Нет данных",
	totalStartingCost: "Итого стартовая стоимость",
	calendarTerm: "календарный срок",
	deliveryAddress: "Адрес доставки",
	contractNumber: "Номер договора",
	contractDate: "Дата договора",
	productDetail: "Характеристика товара",
	customerInn: "ИНН заказчика",
	customerRegion: "Регион заказчика",
	customerDistrict: "Район заказчика",
	customerAddress: "Адрес заказчика",
	customersPersonalAccountWithTheTreasury: "Лицевой счет заказчика в Казначействе",
	perforemersRegion: "Регион исполнителя",
	performersDistrict: "Район исполнителя",
	performersAddress: "Адрес исполнителя",
	performerInformation: "Адрес исполнителя",
	questionCategories: "Категории вопросов",
	allSuppliers: "Все поставщики",
	voted: "Проголосовано",
	signedContracts: "Заключенных договоров",
	totalDealAmount: "Общая сумма сделок (млн. сум)",
	participants: "Участников",
	products: "Товаров",
	perday: "За день",
	usefulLinks: "Полезные ссылки",
	enterProductNumberOrName: "Введите номер или наименование продукта...",
	instructionSupplierRegistration: "Инструкция поставщик регистрация",
	instructionSupplierAuction: "Инструкция поставщик магазин",
	instructionCustomerRegistration: "Инструкция заказчик регистрация",
	instructionCustomerPlanGraphic: "Инструкция заказчик регистрация",
	instructionCustomerAuction: "Инструкция заказчик регистрация",
	instructionSupplierShop: "Инструкция поставщику по магазину",

	instructionCustomerPlanSchedule: "Инструкция заказчику план график",
	instructionsCustomerAuction: "Инструкция заказчику по аукциону",
	instructionsCustomerShop: "Инструкция заказчику по магазину",
	instructionsCustomerRegistration: "Инструкция заказчику по регистрации",
	instructionsCustomerBudget: "Инструкция по получении справки",
	instructionsCustomerBudgetAll:
		"Интструкция по регистрации бюджетных организаций, созданию плана-графика и проведению закупок на платформе электронного кооперационного портала",
	instructionService: "Инструкция по выставлению работ и услуг",
	suppliersInstructionsAuction: "Инструкция поставщику по аукциону",
	suppliersInstructionsShop: "Инструкция поставщику по магазину",
	suppliersInstructionsRegistration: "Инструкция поставщику по регистрации",

	instructionCustomerPlanScheduleSize: "97 kB",
	instructionsCustomerAuctionSize: "700 kB",
	instructionsCustomerShopSize: "1720 kB",
	instructionsCustomerRegistrationSize: "223 kB",
	suppliersInstructionsAuctionSize: "1177 kB",
	suppliersInstructionsShopSize: "1591 kB",
	suppliersInstructionsRegistrationSize: "160 kB",

	learnMore: "Узнать больше",
	latestNews: "Последние новости",
	publishedDate: "Опубликовано",
	shareOnSocialMediaNetworks: "Поделиться в соц. сетях",
	bargainingSubject: "Предмет торга",
	month: "Месяц",
	chooseYear: "Выберите год",
	year: "Год",
	subjectOfTheAuction: "Предмет торгов",
	action: "Действие",
	quarter: "Квартал",
	view: "Просмотр",
	priceCriteriaForPublicProcurement: "Ценовые критерии проведения гос. закупок",
	subjectOfProcurement: "Предмет закупок",
	budgetCustomers: "Бюджетные заказчики",
	corporateCustomers: "Корпоративные заказчики",
	purchaseType: "Вид закупок",
	eShop: "Электронный каталог",
	product: "Товар",
	noLimitSet: "Лимит не установлен",
	notLessThan5WorkingDays: "не менее 5 раб. дней",
	notLessThan30WorkingDays: "не менее 30 раб. дней",
	nameOfGoodsAllowedForPurchaseFromASingleSupplier:
		"Наименование товаров (работ, услуг), разрешенных\n" + "\t\t\t\t\t\t\tдля госзакупки у единого поставщика",
	reason: "Основание",
	advancedSearch: "Расширенный поиск",
	country: "Страна",
	chooseCountry: "Выберите страну",
	manufacturer: "Производитель",
	unitPrice: "Цена за единицу",
	from: "От",
	until: "До",
	more: "Подробнее",
	expirationDate: "Дата окончания",
	showLess: "Показать меньше",
	showMore: "Показать больше",
	orderName: "Наименование заказа",
	deadline: "Срок окончания",
	productQuantity: "Кол-во товара",
	showPeriod: "Показать период",
	procurementInfoByRegion: "Информация о закупках по регионам",
	transactionAmount: "Сумма сделки",
	saving: "Экономия",
	dealAmount: "Количество сделок",
	top5SoldProductCategories: "Топ 5 реализуемых товарных категории",
	categoryName: "Наименование категории",
	selectionOfTheBestOffers: "Отбор",
	collateral: "залоговые средства",
	stateProcurements: "О государственных закупках",
	ResolutionsOfThePresident: "Постановления Президента Республики Узбекистан",
	LawsOfUzb: "Законы Республики Узбекистан",
	DecreesOfThePresident: "Указы Президента Республики Узбекистан",
	provisions: "Положения",
	docText4544:
		"Постановления Президента Республики Узбекистан «О мерах по дальнейшему совершенствованию системы государственных закупок и широкому привлечению субъектов предпринимательства к процессу государственных закупок» от 05.12.2019г. №4544.",
	docText3016:
		"Приказ Национального Агентства Проектного Управления при Президенте Республики Узбекистан “Об утверждении положения о порядке организации и проведения закупочных процедур” (Зарегистрирован Министерством юстиции Республики Узбекистан 26 мая 2018г. Регистрационный № 3016)",
	docText3015:
		"Приказ директора Национального Агентства Проектного Управления при Президенте Республики Узбекистан “Об утверждении положения о деятельности оператора специального информационного портала в отношении организации и проведения государственных закупок” (Зарегистрирован Министерством юстиции Республики Узбекистан 21 мая 2018г. Регистрационный № 3015)",
	docText3013:
		'Приказ директора Национального Агентства Проектного Управления при Президенте Республики Узбекистан "Положение о порядке рассмотрения жалоб в сфере государственных закупок" (Зарегистрирован Министерством юстиции Республики Узбекистан 14 мая 2018 г. Регистрационный № 3013)',
	docText3057:
		"Приказ Национального Агентства Проектного Управления при Президенте Республики Узбекистан “Об утверждении положения о специализированных организациях, имеющих право оказывать услуги по проведению государственных закупок” (Зарегистрирован Министерством юстиции Республики Узбекистан 20 августа 2018 г. Регистрационный № 3057)",
	docText3953:
		"Постановления Президента Республики Узбекистан «О мерах по реализации Закона Республики Узбекистан «О государственных закупках»» от 27.09.2018г. №3953.",
	docTextAuctionContracts:
		"Типовой договор на поставку товаров по результатам проведения электронных государственных закупок на платформе «Аукцион на понижение стартовой цены»",
	docTextShopContracts:
		"Типовой договор на поставку товаров по результатам проведения электронных государственных закупок на платформе «Электронный католог»",
	servicesText1:
		"Дидокс — это самый доступный сервис электронных счетов-фактур. Он бесплатный для большинства предприятий и самый выгодный для всех остальных.",
	servicesText2:
		"При использовании программного продукта 1С Бухгалтерия от Venkon, вы можете использовать возможности интеграции данных прямо из вашей системы Бухгалтерии.",
	servicesText3:
		"Сервис позволяет клиентам банка осуществлять банковские операции прямо из своих учетных систем «1С» и «Didox».",
	servicesText4: "Мы заботимся о надежных поставщиках, качестве товаров и безопасной оплате.",

	numberTrade: "Количество торгов",
	tradingVolume: "Объем торгов",
	signInOrder: "Войдите, чтобы заказать",
	requestPrice: "Запросить цену",
	count: "Количество",
	productDescription: "Описание товара",
	minMaxParty: "Мин/макс партия",
	tradingUntilActive: "Торг активен до",
	deliveryTime: "Срок доставки",
	brand: "Марка",
	manufacturerCountry: "Страна производитель",
	shelfLife: "Срок годности",
	warrantyPeriod: "Гарантийный период",
	warrantyTerms: "Условия гарантии",
	prodYearAndMonth: "Год и месяц изготовления",
	techSpecifications: "Технические параметры",
	epidemiological: "Эпидемиологические заключения",
	environmental: "Экологическая экспертиза",
	autoRenewal: "Автопродление",
	conclusions: "Ветеринарно-санитарные заключения",
	sertificate: "Наличие сертификата",
	phytosanitary: "Фитосанитарные заключения",
	license: "Наличие лицензии",
	veterinary: "Ветеринарные заключения",
	nationalManufacturer: "Национальный производитель",
	deliveryRegion: "Регион доставки",
	deliveryRegions: "Регион доставки",
	relativeProducts: "Похожие товары",
	showAll: "Показать все",
	request: "Запросить",
	protocolText01: "Протокол утверждения закупочной документации по отбору",
	protocolText02: "Протокол на проверку соответствия",
	protocolText03: "Протокол возобновления отбора",
	protocolText04: "Протокол отмены отбора",
	protocolText05: "Протокол определения победителя отбора, балльный метод",
	protocolText06: "Протокол определения победителя отбора, метод наименьшей цены",
	protocolText07: "Протокол оценки технической части отбора",
	protocolText08: "Протокол приостановления отбора наилучших предложений",
	protocolText09: "Отчет о проведении тендера",
	protocolText10: "Протокол возобновления тендера",
	protocolText11: "Протокол на проверку соответствия формальным требованиям",
	protocolText12: "Протокол определения победителя тендера, балльный метод",
	protocolText13: "Протокол определения победителя отбора, метод наименьшей цены",
	protocolText14: "Протокол отмены тендера",
	protocolText15: "Протокол оценки квалификационной части тендера",
	protocolText16: "Протокол оценки технической части тендера",
	protocolText17: "Протокол приостановления тендера",
	protocolText18: "Протокол утверждения тендерной документации",
	modelContracts: "Типовые договора",
	typicalProtocols: "Типовые протоколы",
	fileInstruction: "Файлы инструкции",
	registrationQuestions: "Вопросы по регистрации",
	scheduleQuestions: "Вопросы по план графику",
	scheduleAuctions: "Вопросы по Аукциону",
	scheduleShops: "Вопросы по Магазину",
	selectionQuestions: "Вопросы по Отбору",
	tenderQuestions: "Вопросы по Тендеру",
	questionsPayment: "Вопросы по Оплате",
	questionsOperator: "Вопросы о комиссионных услугах оператора",
	popularQuestions: "Популярные вопросы",
	electronicDidox: "Электронный документ оборот DIDOX",
	integration1C: "Интеграция с программой 1С",
	dibankFeatures: "Возможности Dibank",
	unitMonitoring: "Мониторинг подразделений",
	otherDocuments: "Другие документы",
	select_all: "Выбрать все",
	plan_schedule: "План график",
	incompleteTransactions: "Несовершенные сделки",
	end: "-------------------DO NOT PASS THIS LINE--------------------------",

	main_title: "Специальный информационный портал государственных закупок",
	sourceOfFinancing: "Источник финансирования",
	district: "Район",
	deliveryConditions: "Условия поставки",
	customerPhone: "Телефон заказчика",
	form_plan_schedule: "Формирование шаблонов планов-графиков",
	unifiedRegisterOfTheContract: "Единый реестр договоров",

	instructionCustomerSelectionTender: "Инструкция заказчик отбор тендер",
	instructionSupplierSelectionTender: "Инструкция поставщик отбор тендер",

	instructionCustomerSelectionTenderSize: "477kB",
	instructionSupplierSelectionTenderSize: "463kB",

	docTextEthical: "Правила этического поведения",
	docTextEthicalSize: "30 kB",

	infact: "По факту",
	quarterly: "Квартально",
	exactDate: "Точная дата",
	yes_or_no: "Да/Нет",
	agree_or_disagree: "Согласен/Не согласен",
	critical: "Критично",
	preferably: "Предпочтительно",
	systemic: "Системная",
	expert: "Экспертный",

	role: "Роль",
	clear: "Очистить",
	dealDate: "Дата сделки",
	selection_prepayment: "Предоплата",
	selection_without_prepayment: "Без предоплаты",
	selection_payment_order: "Порядок оплаты",

	type: "Тип торга",
	selectionQuatity: "Отбор",

	// new leng
	directContractsUpperCase: "ПРЯМЫЕ ДОГОВОРА",
	otherTreatiesUpperCase: "ДРУГИЕ ДОГОВОРА",
	auctionUpperCase: "АУКЦИОН",
	shopUpperCase: "МАГАЗИН",
	selectionUpperCase: "ОТБОР",
	tenderUpperCase: "ТЕНДЕР",
	nameOne: "Название",
	tnVed: "ТН ВЭД",
	unitMeasurement: "Ед. измерения",
	gostStandards: "Стандарты ГОСТ (ISO)",
	startDate: "Дата начала",
	endDate: "Дата окончания",
	importAlternativeRequest: "Запрос на альтернативу импорта",
	priceFrom: "Цена-от",
	priceTo: "Цена-до",

	decreesofTheCabinetOFMinisters: "Постановления Кабинета Министров",
	docText833:
		"Постановление Кабинета Министров Республики Узбекистан «Об утверждении порядка размещения информации и осуществления закупок Центра цифровой трансформации при Министерстве инвестиций и внешней торговли на портале электронной кооперации» от 30.09.2019 г. № ПКМ 833",
	docText276:
		"Постановление Кабинета Министров Республики Узбекистан «Об утверждении Положения о порядке организации и проведения процедур, связанных с осуществлением государственных закупок» от 20.05.2022 г.№ ПКМ 276",
	link_1: "Сенат Олий Мажлиса Республики Узбекистан",
	link_2: "Таможенный комитет при Министерстве экономики и финансов Республики Узбекистан",
	link_3: "Министерство инвестиций, промышленности и торговли Республики Узбекистан",
	link_4: "Налоговый комитет при Кабинете Министров Республики Узбекистан",
	link_5: "Законодательная Палата Олий Мажлиса Республики Узбекистан",
	link_6: "Министерство экономики и финансов Республики Узбекистан",
	link_7: "Агентство статистики при Президенте Республики Узбекистан",
	link_8: "Узбекское агентство по техническому регулированию",

	feedback2: "Обратная связь",
	supplier_1_title: "Навоий кон металлургия",
	supplier_2_title: "Узбекнефтегаз",
	supplier_3_title: "Алмалык кон металлургия",
	supplier_4_title: "AKFA",
	supplier_1_description:
		'Акционерное общество "Навоийский горно-металлургический комбинат" (АО "НГМК") входит в десятку крупнейших мировых компаний-производителей золота. Комбинат является промышленным кластером, освоившим полный цикл производства от геологической разведки запасов в недрах, добычи руды и её переработки до получения готовой продукции.',
	supplier_2_description:
		"Узбекнефтегаз холдинговая корпорация, занимающаяся геологоразведкой, добычей, транспортировкой, хранением, переработкой и реализацией нефти и газа. Крупнейшая государственная компания в Узбекистане.",
	supplier_3_description:
		"АО «Алмалыкский ГМК» является одним из крупнейших промышленных предприятий Узбекистана, которое за 70-летнюю историю внесло достойный вклад в развитие экономического потенциала нашей страны, который производственной мощностью базируется на запасах группы медно-молибденовых, свинцово-цинковых и золото-серебряных месторождений, располагающихся на территориях Ташкентской, Джизакской, Наманганской, Сурхандарынской областей Республики Узбекистан.",
	supplier_4_description:
		"За годы существования компания AKFA зарекомендовала себя как надежного и ответственного производителя, в продукции которого вы можете быть уверены.",
	anAuction: "Аукцион",
	signInAlternativeImport: "Войдите, чтобы предложить!",
	listOfRecommended: "Перечень рекомендуемых документов",
	theVolumeOfIndustrial: "Объем производства промышленной продукции",
	proposalForCooperation: "Предложение о сотрудничестве",
	inportAndExportVolme: "Объем импорта и экспорта",
	stateRealEstate: "Государственная недвижимость",
	budgetPurtchases: "Бюджетные закупки: планы-графики",
	corporateProcurement: "Корпоративные закупки: планы-графики",
	operatorDetailsForNon: "Реквизиты оператора для внебюджетных организаций",
	electronicCooperationPortal: "Электронный кооперационный портал Республики Узбекистан",
	requisitesForBudgetary: "Реквизиты для бюджетных организаций",
	specialist: "Спец. л/счет для оплаты комиссионного сбора",
	pr: "Р/С",
	ls: "Л/С",
	street: "ул",
	phone: "Телефон",
	eMail: "E-mail",
	electronicCooperationPortal3: "Электронный кооперационный портал",
	for: "За",
	against: "Против",
	onlineExhibitions: "Онлайн выставки",
	registerOfManufacturersDealers: "Реестр производителей/дилеров",
	numberOfAddedItems: "Количество добавленных товаров",
	participantType: "Тип участника",
	dealer: "Дилер",
	manufacturerDealer: "Производитель/дилер",
	functionalCharacteristics: "Функциональные характеристики",

	sum: "сум",
	pickup: "Самовывоз",
	specifyQuantity: "Укажите кол-во",
	generalCharacteristics: "Общие характеристики",
	numberOfBidsSubmitted: "Количество выставленных оферт",
	goodsOfOrganizationsRelatedPersonsWithDisabilities:
		"Товары организаций, относящихся к обществам лиц с инвалидностью",
	electricalEquipmentUzeltekhsanoat: "Электротехническая продукция (Узэлтехсаноат)",
	TNVEDCode: "Код ТНВЭД",
	mechanismQuoteRequestAvailableForCustomers: "Механизм запроса цен Запрос цен доступен для заказчиков",
	documentsTransferredWithTheGoods: "Перечень передаваемой с товаром документацией",
	tvedCode: "Код ТН ВЭД",
	itemOfExpenses: "Статья расходов",
	binary: "Бинарное",
	textual: "Текстовое",
	numeric: "Числовое",
	logIn: "Вход",
	startPrice: "Стартовая цена процедуры",
	amountOfDeal: "Сумма договора",
	startingPrice: "Стартовая цена",
	cooperation: "Кооперационный портал",
	cooperation_child: "В разработке",
	pay: "Оплатить",
	unPrePaid: "Без предоплаты",
	prePaid: "С предоплатой",
	setPrePaidPercent: "Укажите процент предоплаты",
	notRequestPrice: "Вы не можете запросить цену для своего товара",
	developing: "В разработке",
	poweredbyCDT: "Центр цифровой трансформации",
	mechanismQuoteRequestNotAvailable: "Механизм запроса цен ещё не активен",
	minFt: "Министерство инвестиций, промышленности и торговли Республики Узбекистан",
	limitIsNotChecked: "Лимит не установлен",
	contractsCount: "Заключенных договоров",
	contractsPrice: "Общая сумма сделок (млн. сум)",
	participantsCount: "Участников",
	productsCount: "Товаров",
	bannerText1: "Центр цифровой трансформации",
	bannerText1Under: "Оператор электронной системы государственных закупок",
	bannerText2: "Предложения по 100 инвестиционным проектов",
	bannerText2Under: "В рамках «Открытого диалога» Президента Республики Узбекистан с предпринимателями",
	bannerText5: "Каталог продукции",
	bannerText5Under: "Каталог продукции Республиканской межотраслевой промышленной ярмарки, проводимой в 2024 году",
	yearly: "За год",
	aboutPortalTitle:
		"Портал разработан в соответсвии с Постановление Президента Республики Узбекистан «О мерах по дальнейшему развитию промышленной кооперации и расширению производства востребованной продукции» №ПП-4302 от 01.05.2019 года и Постановление Кабинета Министров Республики Узбекистан «О мерах по организации деятельности промышленной ярмарки и электронной кооперационной биржи» №833 от 30.09.2019 года.",
	aboutPortalSubtitle:
		"Электронный кооперационный портал – это специальная торговая площадка, созданная с целью укрепления и развития кооперационных связей в промышленности между отечественными производителями, расширения производства промышленной продукции и широкого вовлечения субъектов малого бизнеса в промышленную деятельность.",
	testingMode: "Сайт на стадии разработки",
	instructionForUsers: "Регистрация",
	instructionForProducts: "Инструкция по выставлению товаров",
	executor: "Исполнитель",
	periodOfExecution: "Срок исполнения",
	protocols: "Протоколы",
	dealStatus: "Статус сделки",
	sourceFunded: "Источник финансированые",
	calculate: "Расчитать",
	myFilters: "Мои фильтры",
	onlineFair: "Ярмарка",
	authForVote: "Войдите, чтобы голосовать",
	timerText: "Время начала торгов: 03.07.2023 9:00",
	instructionForCert: "Инструкция по добавлению сертификатов",
	instructionForDiler: "Инструкция по добавлению дилера",
	day2: "Дня",
	day3: "День",
	day4: "Дней",
	certificated: "Сертифицирован",
	notCertificated: "Не сертифицирован",
	inAYear: "За год",
	titleOfWithoutPercent: "Типовая форма договора (Электронный каталог, полная оплата)",
	titleOfWithPercent: "Типовая форма договора (Электронный каталог, предоплата)",
	eSignKeyError: "Пароль введён неправильно",
	instructionForCellWithoutPrePayment: "Инструкция по торговому процессу (без предоплаты)",
	instructionForRejectingOrEnablingCell: "Инструкция по оплате и расторжению договоров",
	inDevelopingMode: "В режиме разработки",
	abrobation: "Сайт на стадии апробации",
	questionnaire: "Опросник",
	gotoQuestionaree: "Пройдите опросник",
	typeOfPayment: "Тип оплаты",
	artisans: "Изделия, сделанные ремесленниками",
	bannerText4:
		"С 12 по 15 декабря в столичном Национальном выставочном комплексе «Узэкспомарказ» проходит Республиканская межотраслевая промышленная ярмарка, региональные этапы которой были проведены во всех регионах страны и Республике Каракалпакстан в течение 2023 года.",
	registerOfActiveTrades: "Реестр активных торгов",
	numberOffer: "№ оферты",
	maxAmount: "Макс. кол-во",
	allActiveTrades: "Все активные торги",
	ourNews: "Преимущества и удобства",
	titleOfFeatutures:
		"Созданные преимущества и удобства на Электронном кооперационном портале Центра цифровой трансформации",
	feature1:
		"Разработана возможность видеть в персональных кабинетах поставщиков <b>победителя торгов после истечения срока торга</b>.",
	feauture2Title: "<b>Запросы</b> были разделены на:",
	feature2Text1: "все запросы;",
	feature2Text2: "мои запросы (активные запросы).",
	feauture2FinalText: "А также, выполнена задача по сохранению всех запросов и уведомлений в базе данных.",
	feauture3Title: "Разработана возможность просмотра <b>полной истории</b> торга после ее окончания, в том числе:",
	feauture3Text1: "когда запрос был отправлен;",
	feauture3Text2: "когда запрос был принят;",
	feauture3Text3: "дата начала и окончания торга;",
	feauture3Text4: "реестр участников, которые получили рассылку по торгу;",
	feauture3Text5: "ценовое предложение участника торга, а также количество и время;",
	feauture3Text6: "данные о победителе торга.",
	feauture4: "Усовершенствован механизм <b>формирования и ведения реестра договоров</b> на Портале.",
	feauture5:
		"Внедрена функция <b>взимания штрафа</b> или рассторжения договора при не оплате суммы заказчиком по истечению <b>18 банковских рабочих дней</b> с момента формирования договора.",
	feauture6:
		"Выполнена задача по разделению <b>кодов требующих сертификат по категориям</b> (обязательно / не обязательно).",
	feauture7: "Внедрена функция по <b>СМС уведомлению</b> о начале торгов, по первым 4 знакам кода ТН ВЭД.",
	feauture8:
		"Внедрена <b>функция уведомления</b> при истечении срока дилерства и сертификата, а также при обнаружении импорта по активной оферте.",
	feauture9: "Разработана возможность видеть <b>детализацию штрафа</b> при расторжения договора.",
	feauture10Title: "<b>Цифровизирован</b> порядок осуществления модерации оферт, то есть:",
	feauture10Text1: "все оферты проходят модерацию по очереди;",
	feauture10Text2:
		"внедрена возможность осуществления постоянного мониторинга очереди собственного продукта через свой персонльный кабинет;",
	feauture10Text3:
		"в случаях модерации оферт без очереди, данная оферта не будет активна сразу, система автоматически переведет ее в активное состояние в свою очередь.",
	feauture10FinalText:
		"Модерацияга навбат механизми: агар навбатда бошқа оферталар мавжуд бўлса оферта маъқуллангандан сўнг дарҳол фаол бўлмайди, кутиш режимига ўтказилади.",
	feauture11:
		"В целях обеспечения прозрачности процесса торгов, был осуществлен <b>взаимный обмен информацией (интеграция)</b> совместно с Счетной палатой, Комитетом по развитию конкуренции и защите прав потребителей и Антимонопольным комитетом.",
	feauture12:
		"Внедрена возможность <b>мониторинга реестра активных торгов</b> и данных по ним пользователями Портала <b>в реальном времени</b>.",
	readTheFeautures: "Я ознакомился",
	notificationText: "Уведомление",
	support: "Поддержка",
	code2022: "Коды ТН ВЭД (2022)",
	offerUntilActive: "Оферта активна до",
	cooperAdress: "100027, г. Ташкент, Шайхантахур, ул. Коратош, 2A",
	socialMedia: "Социальный сети",
	typeSms: "Введите код отправленный на ваш номер",
	send: "Отправить",
	comment: "Коментарий",
	participantAccountInStore: "Номер сберегательного счета клиента",
	monthCountOfPayment: "Месяц оплаты",
	selectRegion: "Пожалуйста, выберите регион",
	fullSearchFromList: "Расширенный поиск для получения справки",
	getInformation: "Получить справку",
	typeFirst3LettersOfProduct: "напишите первые 3 буквы продукта",
	typeFirst3LettersOfCode: "впишите первые 4 цифры кода ТН ВЭД",
	pictureOfItem: "Изображение продукта",
	rejectAnswer1: "Отсутствие товара (работы, услуги)",
	rejectAnswer2: "Недостаточный объем товара (работы, услуги)",
	rejectAnswer3: "Качество, гарантия, технические параметры товара (работы, услуги) не соответствуют уровню спроса",
	rejectAnswer4: "Длительный (долгий) срок доставки",
	rejectAnswer5: "Другие причины",
	rejectionAnnouncement:
		"Несоблюдение требований обязательного изучения возможности приобретения товаров (работ, услуг) у местных производителей, зарегистрированных на портале, в первую очередь, влечет за собой соответствующую ответственность.",
	productNameMin: "Название товара должно состоять минимум из 3 букв",
	sendedRequestToDMBAt: "Запрос отправлен в ИСУГФ",
	restoringTitle: "ВНИМАНИЕ, ОБЪЯВЛЕНИЕ!!!",
	restoringText:
		"Уважаемые пользователи электронного кооперационного портала, уведомляем Вас о проведении профилактических работ на нашем сайте. В связи с этими работами, доступ к сайту будет временно ограничен. Приносим извинения за возможные неудобства и благодарим за понимание.",
	notFound: "По вашему запросу ничего не найдено",
	loginForInfo: "Войдите чтобы получить справку",
	goToPersonalCabinet: "Перейти в личный кабинет",
	registerOfSingleBusnises: "Реестр единых поставщиков",
	tableSupllierName: "Наименование поставщика товара (товара, услуги)",
	tableSupllierType: "Название продукта (товара, услуги)",
	statusName: "Состояние",
	codeOfKlassifikator: "Код классификатора",
	dateOfSupplierIn: "Дата внесения в реестр",
	purpose: "Основание",
	buxoroTopProducts: "Топ-продукты Бухарской области",
	numberOfActiveOffers: "Количество активных оферт",
	disabilityCompanyText:
		"\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0 Уважаемый клиент, оферта, которую вы выбрали, относится к товарам организации, относящихся к обществам лиц с инвалидностью. Сообщаем, что все сделки по этим товарам будут осуществляться на основе прямых договоров.",
	numberOfOffers: "Количество оферт",
	active: "Активные",
	all: "Все",
	registrationDate: "Дата регистрации",
	greenShopping: "Зеленые закупки",
	budget: "Бюджет",
	typeOrganization: "Тип организации",
	getAllCompanies: "Список зарегистрированных организации",
	numberOfOffersByCorp: "Количество оферт производителя",
	numberOfOffersByDiler: "Количество оферт дилера",
	corporative: "Корпоратив",
	providerOrCustomer: "Статус организации",
	customer2: "Заказчик",
	direct_contract: "Прямой договор",
	next: "Следующий",
	back: "Предыдущий",
	enktCode: "Код ЕНКТ",
	epFindings: "Эпидемиологические заключения",
	verOp: "Ветеринарные заключения",
	phyConc: "Фитосанитарные заключения",
	vetSan: "Ветеринарно-санитарные заключения",
	enAssessment: "Экологическая экспертиза",
	no: "Нет",
	yes: "Да",
	thereIs: "Есть",
	instructionGovernment: "Инструкция по отправке обращения для добавления товара",
	goods: "Товары",
	work_and_services: "Работы и услуги",
	enter_name: "Введите свое имя",
	phone_number: "Номер телефона",
	enter_phone_number: "Введите свой номер телефона",
	your_email: "Введите свой адрес электронной почты",
	registerOfDirectTrades: "Реестр прямых сделок",
	ordinalNumber: "№",
	organizationNameCustom: "Название организации (заказчика)",
	orderingTin: "СТИР информация заказчика",
	companyNameSupplier: "Название организации (поставщика)",
	suplierTin: "СТИР информация поставщика ",
	addComment: "Добавить комментарий",
	companyComment: "Название компании, оставившей комментарий",
	companyCommentTin: "Информация СТИР о компании, оставившей комментарий",
	comments: "Комментарии",
	commentAdded: "Комментарий добавлен",
	errorOccurred: "Произошла ошибка",
	commentRequirements:
		"Напоминаем!  Комментарии, унижающие честь и достоинство человека и распространяющие необоснованную и ложную информацию, могут подлежать административной (статья 41 Кодекса Республики Узбекистан об административной ответственности) и уголовной (статья 140 Уголовного кодекса Республики Узбекистан) ответственности!",
	fullName: "Ф.И.О",
	pleaseRegister: "Чтобы оставить комментарий, пожалуйста, зарегистрируйтесь.",
	add: "Добавить",
	warning: "Предупреждение",
	allProductsInPortal: "Все товары, размещенные на портале",
	allServicesInPortal: "Все услуги (работы), размещенные на портале",
	moderationProducts: "Все товары",
	moderationProductsDealer: "Из них дилеры",
	activeProducts: "Активные товары",
	moderationServices: "Все услуги (работы)",
	activeServices: "Активные услуги (работы)",
	soonStartLaw: 'Постановлением Кабинета Министров «Об утверждении стратегии совершенствования и развития системы государственных закупок на 2025-2027 годы» от 20 декабря 2024 года утвержден план мероприятий по реализации стратегии совершенствования и развития системы государственных закупок на 2025-2027 годы. Данным планом операторам электронных систем государственных закупок поручено внедрение всех видов закупочных процедур в своих электронных системах. В целях обеспечения эффективной реализации Постановления сообщаем Вам, что в настоящее время данный модуль находится в стадии запуска на Электронном кооперационном портале.',
	importProducts: "Импортные товары"
};
